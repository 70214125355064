import React, { useEffect, useState } from 'react';
import {
  Table,
  TableProps,
  TableCellProps,
  TableHeaderCellProps,
  TableHeaderProps,
  TableBodyProps,
  TableRowProps,
  TableFooterProps,
} from 'semantic-ui-react';
import classNames from 'classnames';
import styles from './ATMTable.module.scss';

export type cols = 'first' | 'last' | string;

export type IATMTableProps = TableProps & {
  fixedColumns?: cols;
  stickyHeader?: boolean;
  enableScroll?: boolean;
  compress?: boolean;
};
interface IATMTable extends React.FC<IATMTableProps> {
  Header: typeof Table.Header;
  Body: typeof Table.Body;
  Row: typeof Table.Row;
  HeaderCell: typeof Table.HeaderCell;
  Cell: typeof Table.Cell;
  Footer: typeof Table.Footer;
}

const ATMTable: IATMTable = ({
  children,
  stickyHeader,
  enableScroll,
  fixedColumns,
  compress,
  ...props
}) => {
  const [shadow, setShadow] = useState(false);
  const [shadowLastColumn, setShadowLastColumn] = useState(false);
  const windowWidth = window.innerWidth;

  useEffect(() => {
    const docWidth = document.getElementById('tableWrapper')?.clientWidth;
    if (docWidth) {
      if (docWidth > windowWidth) {
        setShadowLastColumn(true);
      }
    }
  }, []);
  const handleScroll = () => {
    const scrollElement = document?.getElementById('divWrapper');

    const scrollPosition = scrollElement?.scrollLeft;

    if (scrollPosition && scrollPosition > 0) {
      setShadow(true);
    } else {
      setShadow(false);
    }
  };

  if (enableScroll) {
    return (
      <div
        id="divWrapper"
        className={stickyHeader ? styles.noWrapperTable : styles.wrapperTable}
        onScroll={handleScroll}
      >
        <div
          className={classNames({
            [styles.stickyColumns]: fixedColumns === 'first',
            [styles.stickyColumnsLast]: fixedColumns === 'last',
            [styles.shadowColumn]:
              shadow === true && fixedColumns !== undefined,
            [styles.shadowLastColumn]:
              shadowLastColumn === true && fixedColumns !== undefined,
          })}
        >
          <Table
            className={classNames(props.className, {
              [styles.compress]: compress,
            })}
            {...props}
            id="tableWrapper"
          >
            {children}
          </Table>
        </div>
      </div>
    );
  }
  return (
    <Table
      className={classNames(props.className, {
        [styles.compress]: compress,
      })}
      {...props}
    >
      {children}
    </Table>
  );
};

ATMTable.Header = Table.Header;
ATMTable.Body = Table.Body;
ATMTable.Row = Table.Row;
ATMTable.HeaderCell = Table.HeaderCell;
ATMTable.Cell = Table.Cell;
ATMTable.Footer = Table.Footer;

export type IATMTableHeaderProps = TableHeaderProps;
export type IATMTableBodyProps = TableBodyProps;
export type IATMTableRowProps = TableRowProps;
export type IATMTableHeaderCellProps = TableHeaderCellProps;
export type IATMTableCellProps = TableCellProps;

export type IATMTableFooterProps = TableFooterProps;

export { ATMTable };
