import React from 'react';
import { ATMModal } from '../../atoms/ATMModal/ATMModal.component';
import { ATMIcon } from '../../atoms/ATMIcon/ATMIcon.component';
import { ATMHeader } from '../../atoms/ATMHeader/ATMHeader.component';
import styles from './MOLRightPanel.module.scss';
import { ATMPopover } from '../../atoms/ATMPopover/ATMPopover.component';

export type IMOLRightPanelProps = {
  panelContent?: React.ReactNode;
  header?: string;
  popoverHeader?: string;
  popoverContent?: string;
  subHeaderContent?: React.ReactNode;
  panelFooter?: React.ReactNode;
  width?: string;
  isOpen?: boolean;
  onClose: () => void;
};

const MOLRightPanel: React.FC<IMOLRightPanelProps> = ({
  panelContent,
  header,
  popoverHeader,
  popoverContent,
  subHeaderContent,
  panelFooter,
  width = '40%',
  isOpen = false,
  onClose,
}) => {
  return isOpen ? (
    <div className={styles.tmpFloat}>
      <ATMModal
        className={styles.floatingPanel}
        dimmer="inverted"
        style={{ width }}
        open
        closeOnDimmerClick={false}
      >
        <ATMModal.Header className={styles.noPadding}>
          <div>
            <div className={styles.panelHeader}>
              <ATMHeader as="h1" className={styles.textMargin}>
                {header}
              </ATMHeader>
              <div className={styles.flex}>
                {popoverContent && (
                  <ATMPopover
                    on="click"
                    position="bottom left"
                    className={styles.popoverHeight}
                    basic
                    content={popoverContent}
                    header={popoverHeader}
                    trigger={
                      <ATMIcon name="question circle outline" size="small" />
                    }
                  />
                )}
                <ATMIcon name="delete" onClick={onClose} size="small" />
              </div>
            </div>
          </div>
        </ATMModal.Header>
        <ATMModal.Content scrolling className={styles.floatPanelContent}>
          {subHeaderContent && (
            <div className={styles.panelSubHeader}>{subHeaderContent}</div>
          )}
          <div className={styles.contentArea}>{panelContent}</div>
        </ATMModal.Content>
        <ATMModal.Actions className={styles.rightPanelFooter}>
          {panelFooter}
        </ATMModal.Actions>
      </ATMModal>
    </div>
  ) : null;
};

export { MOLRightPanel };
