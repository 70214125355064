import React, { forwardRef, useMemo } from 'react';
import { Controller, UseControllerOptions } from 'react-hook-form';
import { FormFieldProps, Form, Ref } from 'semantic-ui-react';
import ClassNames from 'classnames';
import { ATMCheckbox } from '../ATMCheckbox/ATMCheckbox.component';
import styles from './ATMField.module.scss';

export type IATMFieldProps = Omit<UseControllerOptions, 'defaultValue'> &
  Omit<FormFieldProps, 'control'> & {
    name: string;
    defaultChecked?: boolean;
    onChange?: (
      data: [React.ChangeEvent<HTMLFormElement>, any]
    ) => any | ((event: React.ChangeEvent<HTMLFormElement>) => void);
    onBlur?: (event: React.FocusEvent<HTMLFormElement>, data: any) => void;
  };

type IError = {
  message: string;
};

const createRef =
  (Element: any, ref: any): React.FC =>
  ({ children, ...props }) =>
    (
      <Ref innerRef={ref}>
        <Element {...props}>{children}</Element>
      </Ref>
    );

export const ATMField: React.FC<IATMFieldProps> = forwardRef(
  (props, inputRef) => {
    const { error, as: element, control, name, ...fieldProps } = props;

    if (error) {
      fieldProps.error = (error as IError).message
        ? { content: (error as IError).message }
        : error;
    }

    const Element = useMemo(
      () => (inputRef ? createRef(element, inputRef) : element),
      [element, inputRef]
    );

    const { rules, defaultValue, ...newProps } = fieldProps;

    if (!control) {
      return (
        <Form.Field
          {...newProps}
          defaultValue={defaultValue || fieldProps.defaultChecked}
          className={ClassNames(styles.wrapper, fieldProps.className)}
          control={Element}
        />
      );
    }

    return (
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue || fieldProps.defaultChecked}
        rules={rules}
        render={({ onChange, onBlur, ref, ...childProps }) => {
          const newFieldProps: any = {
            ...newProps,
            ...childProps,
            onChange,
          };

          if (newProps.onChange) {
            newFieldProps.onChange = (...args: any[]) =>
              newProps.onChange && onChange(newProps.onChange(args as any));
          }

          if (Element === ATMCheckbox) {
            newFieldProps.checked =
              newFieldProps.checked === undefined
                ? !!newFieldProps.value
                : newFieldProps.checked;

            newFieldProps.value = newFieldProps.checked ? 1 : 0;

            if (newFieldProps.inline) {
              return (
                <Form.Field error={newFieldProps.error}>
                  <ATMCheckbox {...newFieldProps} />
                </Form.Field>
              );
            }
          }

          return (
            <Form.Field
              {...newFieldProps}
              className={ClassNames(styles.wrapper, newProps.className)}
              control={Element}
              onBlur={(event: React.FocusEvent<HTMLFormElement>) => {
                if (newProps.onBlur) {
                  const value = newProps.onBlur(event, childProps.value);

                  if (value !== undefined) {
                    onChange(value);
                  }
                }

                onBlur();
              }}
            />
          );
        }}
      />
    );
  }
);
