import React from 'react';

type IProps = {
  width?: number;
  height?: number;
  color?: string;
  logoName?: string;
};

export const ATMBrandLogo: React.FC<IProps> = ({
  height,
  width,
  color,
  logoName = 'sempra energy',
}) => {
  let h = 119.6;
  let w = 482.17334;

  if (width && height) {
    h = height;
    w = width;
  } else if (width) {
    const percent = width / w;

    w = width;
    h *= percent;
  } else if (height) {
    const percent = height / h;

    h = height;
    w *= percent;
  }

  const getLogo = () => {
    const sempraLogo = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 482.17334 119.6"
        height="119.6"
        width="482.17334"
        xmlSpace="preserve"
        id="svg2"
        version="1.1"
        style={{ width: w, height: h }}
      >
        <g transform="matrix(1.3333333,0,0,-1.3333333,0,119.6)" id="g10">
          <g transform="scale(0.1)" id="g12">
            <path
              id="path14"
              style={{
                fill: color ?? '#204394',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none',
              }}
              d="m 3570.46,434.621 c -26.05,0 -45.87,18.547 -45.87,45.863 0,27.313 19.82,45.864 45.87,45.864 26.04,0 45.86,-18.551 45.86,-45.864 0,-27.316 -19.82,-45.863 -45.86,-45.863 z m 0,85.883 c -22.49,0 -39.64,-16.395 -39.64,-40.02 0,-23.632 17.15,-40.019 39.64,-40.019 22.49,0 39.64,16.387 39.64,40.019 0,23.625 -17.15,40.02 -39.64,40.02 z m 8.64,-42.816 11.31,-21.727 h -7.88 l -10.93,21.219 h -10.92 v -21.219 h -6.74 v 50.695 h 20.59 c 9.14,0 16.26,-4.574 16.26,-14.613 0,-7.625 -4.58,-12.703 -11.69,-14.355 z m -4.96,22.996 h -13.46 v -17.153 h 12.7 c 7.24,0 10.67,2.668 10.67,8.512 0,5.715 -3.55,8.641 -9.91,8.641"
            />
            <path
              id="path16"
              style={{
                fill: color ?? '#204394',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none',
              }}
              d="m 647.813,897.004 c 65.597,-129.258 20.636,-243.867 -1.918,-287.793 46.265,53.957 49.335,106.25 49.335,106.25 0,0 8.454,-16.672 11.215,-35.379 23.828,65.504 -1.492,120.379 -1.492,120.379 0,0 56.602,-87.785 -5.039,-165.539 -1.496,12.191 -3.992,21.777 -3.992,21.777 -12.363,-22.969 -38.734,-55.316 -67.473,-77.832 69.852,19.531 101.621,53.961 115.457,99.492 34.973,115.215 -96.093,218.645 -96.093,218.645 v 0"
            />
            <path
              id="path18"
              style={{
                fill: color ?? '#e01049',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none',
              }}
              d="m 736.176,610.969 c -1.395,1.101 -2.949,0.41 -3.645,-0.328 -1.597,-1.477 -81.308,-86.637 -234.609,-66.168 -50.039,6.632 -93.027,18.254 -127.5,26.906 11.094,22.598 19.121,54.773 -13.84,73.406 -47.168,26.653 -111.348,-19.363 -114.996,-25.508 -1.41,-2.375 0.516,-3.73 2.539,-3.886 8.258,-0.821 71.598,0.898 98.738,-37.348 -67.226,16.629 -75.312,-12.113 -65.425,-41.676 5.855,-17.691 21.078,-50.484 42.988,-82.461 0,0 1.48,-2.011 0.043,-3.808 -0.778,-1.028 -2.039,-1.637 -3.278,-2.051 C 210.859,412.473 131.965,307.445 81.6211,217.457 c -24.2578,46.715 -38.0625,99.773 -38.0625,156 0,188.047 153.5044,340.481 342.7894,340.481 36.34,0 71.328,-5.606 104.16,-16.008 l 0.265,0.781 C 450.586,714.102 406.906,722.574 361.27,722.574 161.73,722.574 0,560.813 0,361.289 0,290.25 20.4688,224.004 55.8906,168.152 30.2188,115.254 16.6602,75.5078 15.6836,72.4336 11.1641,58.5508 10.4414,46.957 19.0391,43.0703 26.6797,39.7188 33.332,44.0586 45.6953,61.0508 84.918,115.012 251.438,362.07 367.129,283.906 c 57.504,-38.855 75.598,-74.883 42.109,-108.539 -17.695,-17.738 -59.906,-42.711 -85.351,-54.754 -39.364,-18.582 -60.672,-22.3005 -57.422,-36.1872 3.027,-12.8164 19.523,-12.7383 85.793,4.418 76.14,19.7422 249.004,64.6912 159.726,211.1562 -40.308,66.199 -81.621,116.238 -109.296,157.301 -0.743,1.07 -0.989,2.133 -0.168,3.351 0.949,1.36 3.437,1.157 3.437,1.157 293.57,21.824 329.356,140.718 330.422,144.695 0.242,0.82 1.23,3.309 -0.203,4.465 v 0"
            />
            <path
              id="path20"
              style={{
                fill: color ?? '#e01049',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none',
              }}
              d="M 695.512,226.133 C 640.207,111.816 522.59,32.9219 386.348,32.9219 289.922,32.9219 202.754,72.5117 140.441,136.223 130.137,123.613 120.559,111.328 111.855,99.9023 176.754,38.0859 264.582,0 361.27,0 512.91,0 642.695,93.3945 696.293,225.805 l -0.781,0.328 v 0"
            />
            <path
              id="path22"
              style={{
                fill: color ?? '#204394',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none',
              }}
              d="m 968.43,465.941 h -9.153 l -12.351,35.704 c -7.785,5.945 -26.543,10.972 -46.227,10.972 -31.117,0 -54.918,-13.265 -54.918,-48.519 0,-30.653 27.91,-48.043 63.614,-62.235 37.062,-14.636 81.007,-37.074 81.007,-94.273 0,-55.82 -41.652,-86.043 -98.855,-86.043 -40.277,0 -72.309,17.855 -81.918,24.715 v 49.429 h 10.98 l 10.067,-38.433 c 11.898,-10.528 35.699,-20.156 63.164,-20.156 40.265,0 63.152,21.976 63.152,59.968 0,27 -17.851,48.047 -61.777,65.903 -40.742,16.476 -82.848,36.597 -82.848,90.152 0,42.559 29.301,75.051 87.879,75.051 32.031,0 54.91,-7.778 68.184,-13.719 v -48.516 0"
            />
            <path
              id="path24"
              style={{
                fill: color ?? '#204394',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none',
              }}
              d="m 1057.66,354.734 c 4.13,38.43 22.43,71.391 50.81,71.391 34.78,0 44.85,-44.387 44.85,-71.391 h -95.66 z m -0.9,-15.105 h 133.63 c 1.83,33.41 -10.98,99.316 -81,99.316 -52.63,0 -90.62,-43.015 -90.62,-107.562 0,-61.781 38.89,-108.918 102.97,-108.918 21.51,0 42.1,5.933 62.24,18.301 l -4.57,10.074 c -19.68,-10.074 -33.4,-11.895 -52.18,-11.895 -43.93,0 -70.02,42.559 -70.47,100.684 v 0"
            />
            <path
              id="path26"
              style={{
                fill: color ?? '#204394',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none',
              }}
              d="m 1576.19,226.586 h -69.1 v 145.086 c 0,27 -5.5,49.418 -31.58,49.418 -16.02,0 -43.94,-10.973 -61.33,-31.57 0,0 0,-19.692 0,-146.454 l 26.08,-7.785 v -8.695 h -60.86 v 145.086 c 0,27 -6.41,49.418 -30.67,49.418 -18.3,0 -47.59,-12.82 -62.69,-31.57 V 241.688 l 25.62,-6.407 v -8.695 h -92.44 v 8.695 l 32.03,7.309 v 171.64 l -37.53,7.313 v 8.695 c 24.26,2.746 40.28,4.586 65.91,3.207 l 5.03,-23.328 c 22.88,18.754 48.97,28.828 73.69,28.828 27.46,0 42.56,-14.648 48.97,-33.867 17.39,16.934 48.5,33.867 75.06,33.867 41.19,0 59.49,-22.89 59.49,-74.156 V 242.59 l 34.32,-7.309 v -8.695 0"
            />
            <path
              id="path28"
              style={{
                fill: color ?? '#204394',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none',
              }}
              d="m 1656.27,379.895 c 10.99,22.894 29.31,40.273 51.73,40.273 28.83,0 45.3,-30.195 45.3,-88.785 0,-56.285 -15.1,-94.281 -59.04,-94.281 -8.24,0 -24.71,1.375 -37.99,9.628 v 133.165 z m -1.37,23.335 c -1.37,11.45 -5.04,25.188 -6.4,30.215 -25.18,2.754 -40.27,-0.461 -64.54,-3.207 v -8.695 l 37.54,-7.313 V 146.953 l -34.32,-7.789 v -8.695 h 103.43 v 9.621 l -34.34,6.406 v 85.121 c 9.16,-5.031 24.27,-9.152 40.28,-9.152 50.34,0 96.12,38.453 96.12,108.465 0,57.195 -19.23,108.015 -73.69,108.015 -22.43,0 -48.52,-12.375 -64.08,-35.715 v 0"
            />
            <path
              id="path30"
              style={{
                fill: color ?? '#204394',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none',
              }}
              d="m 1887.39,242.141 33.87,-6.403 v -9.152 h -102.98 v 9.152 l 34.33,6.852 v 171.64 l -37.52,7.313 v 8.695 c 24.25,2.746 38.89,6.41 64.05,3.668 1.85,-5.957 6.41,-15.566 7.34,-27.449 17.84,18.75 38.44,32.488 68.18,32.488 6.41,0 12.37,-0.461 17.41,-2.297 l -13.29,-28.378 c -2.26,1.375 -9.15,4.589 -18.76,4.589 -13.28,0 -32.5,-4.589 -52.63,-25.632 v -145.086 0"
            />
            <path
              id="path32"
              style={{
                fill: color ?? '#204394',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none',
              }}
              d="m 2109.35,277.379 c -10.53,-25.16 -28.38,-37.063 -45.3,-37.063 -21.07,0 -40.27,16.461 -40.27,44.856 0,35.687 26.52,53.539 58.57,53.539 h 27 v -61.332 z m 66.82,-38.902 -32.04,6.41 c 0,135.457 0,77.343 0,124.488 0,52.168 -35.23,69.57 -72.3,69.57 -27.93,0 -56.75,-10.074 -72.32,-22.422 l 9.62,-41.187 10.06,1.816 2.3,29.746 c 7.78,7.782 25.62,14.192 43.48,14.192 22.41,0 44.38,-11.445 44.38,-44.391 v -22.883 h -15.56 c -50.81,0 -105.71,-15.105 -105.71,-75.519 0,-33.41 30.66,-56.293 63.15,-56.293 21.97,0 44.38,10.066 59.49,32.953 l 8.71,-27.914 c 0,0 24.25,-2.277 56.74,2.73 v 8.704 0"
            />
            <path
              id="path34"
              style={{
                fill: color ?? '#204394',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none',
              }}
              d="m 2475.47,265.48 -11.88,-38.894 h -192.7 v 10.516 l 32.04,6.414 v 265.441 l -32.04,6.875 v 10.516 h 181.24 l 11.91,-38.438 -9.61,-4.121 -14.66,24.719 h -99.76 V 395.012 h 97.02 v -17.86 h -97.02 V 244.434 h 110.75 l 15.09,25.168 9.62,-4.122 v 0"
            />
            <path
              id="path36"
              style={{
                fill: color ?? '#204394',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none',
              }}
              d="m 2725.36,226.586 h -67.3 v 145.535 c 0,37.996 -10.52,49.422 -34.78,49.422 -13.72,0 -43.93,-13.273 -58.57,-31.57 V 241.688 l 25.63,-6.407 v -8.695 h -92.46 v 8.695 l 32.04,7.309 v 171.64 l -37.53,7.313 v 8.695 c 24.27,2.746 40.73,5.961 65.91,3.668 l 5.03,-23.789 c 15.56,13.715 44.38,28.828 70.02,28.828 40.28,0 59.49,-21.523 59.49,-72.781 V 242.59 l 32.52,-7.309 v -8.695 0"
            />
            <path
              id="path38"
              style={{
                fill: color ?? '#204394',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none',
              }}
              d="m 2782.08,354.734 c 4.13,38.43 22.44,71.391 50.81,71.391 34.78,0 44.86,-44.387 44.86,-71.391 h -95.67 z m -0.9,-15.105 h 133.62 c 1.84,33.41 -10.98,99.316 -80.98,99.316 -52.64,0 -90.64,-43.015 -90.64,-107.562 0,-61.781 38.9,-108.918 102.97,-108.918 21.52,0 42.12,5.933 62.25,18.301 l -4.58,10.074 c -19.68,-10.074 -33.4,-11.895 -52.18,-11.895 -43.93,0 -70.02,42.559 -70.46,100.684 v 0"
            />
            <path
              id="path40"
              style={{
                fill: color ?? '#204394',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none',
              }}
              d="m 3010.44,242.141 33.89,-6.403 v -9.152 h -102.98 v 9.152 l 34.31,6.852 v 171.64 l -37.52,7.313 v 8.695 c 24.26,2.746 38.9,6.41 64.08,3.668 1.82,-5.957 6.4,-15.566 7.32,-27.449 17.86,18.75 38.45,32.488 68.19,32.488 6.41,0 12.35,-0.461 17.38,-2.297 l -13.26,-28.378 c -2.3,1.375 -9.15,4.589 -18.77,4.589 -13.26,0 -32.48,-4.589 -52.64,-25.632 v -145.086 0"
            />
            <path
              id="path42"
              style={{
                fill: color ?? '#204394',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none',
              }}
              d="m 3143.61,368.004 c 0,32.488 21.52,55.375 46.68,55.375 25.63,0 45.78,-22.887 45.78,-55.375 0,-42.106 -23.81,-58.121 -45.78,-58.121 -21.04,0 -46.68,16.484 -46.68,58.121 z m -16.02,-48.973 c -13.26,-9.629 -21.97,-24.707 -21.97,-39.363 0,-20.121 11.92,-39.352 36.15,-45.305 -27.44,-6.863 -44.38,-27 -44.38,-48.965 0,-27.003 26.08,-54.46 91.98,-54.46 72.33,0 105.74,42.089 105.74,80.539 0,19.691 -8.7,38.449 -25.64,49.89 -14.65,0 -59.04,0 -91.07,0 -37.53,0 -50.35,13.731 -50.35,29.293 0,7.778 4.12,15.563 9.61,20.121 16.93,-11.886 38,-16.457 52.63,-16.457 56.3,0 80.09,37.059 80.09,72.762 0,18.766 -6.85,35.703 -20.13,48.969 l 37.08,6.855 v 12.367 h -63.18 c -8.68,1.844 -24.23,3.668 -33.86,3.668 -51.72,0 -81,-30.675 -81,-71.859 0,-21.051 7.77,-36.606 18.3,-48.055 z m 130.9,-88.777 c 9.15,-5.488 14.19,-16.031 14.19,-27.461 0,-25.18 -22.43,-55.84 -73.23,-55.84 -50.8,0 -67.28,21.035 -67.28,42.11 0,17.851 12.82,35.703 27.92,41.191 h 98.4 v 0"
            />
            <path
              id="path44"
              style={{
                fill: color ?? '#204394',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none',
              }}
              d="m 3343.61,144.648 c 17.85,9.153 36.62,21.524 43.93,41.665 l 16.48,46.226 -67.27,182.59 -30.22,10.078 v 8.699 h 88.78 v -8.699 l -22.41,-7.785 48.05,-141.414 55.85,157.898 h 48.04 v -8.699 l -35.23,-9.602 -80.56,-221.503 c -15.1,-41.196 -27,-51.27 -54.92,-67.747 l -10.52,18.293 v 0"
            />
          </g>
        </g>
      </svg>
    );
    const sdgeLogo = (
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 487.7 90.55"
        style={{ width: w, height: h }}
      >
        <defs />
        <path
          style={{ fill: '#ffffff' }}
          d="M154.29,93.22l-1,3.16-5.09,17.9H133.94c-5.23,0-10.32,3.93-12.35,8.88l.62-2.23,5.37-18.59a14.4,14.4,0,0,1,12.11-9.12Z"
          transform="translate(-0.42 -93.22)"
        />
        <path
          style={{ fill: '#ffffff' }}
          d="M117.68,106.81a4.63,4.63,0,0,1,1.22,3.78l-2.26,8-8.38,0c.46-1.89,1.08-3.69,1.45-5.68a1.65,1.65,0,0,0-.19-1,5.9,5.9,0,0,0-5.84-1.31c-2.73.54-4.85,3.25-6.08,5.68l-.94,2.28-5.09,17.71c-.52,1.7-1.27,3.79,0,5.39,2.07,2.53,5.8,2.48,8.58,1.65.75-.29,1.74-.43,2.17-1.12.75-2.81,1.69-5.62,2.4-8.44l-.19-.1H98.41l2.07-7.47.14-.1c-.05,0-.1,0-.1-.14,4.53-.05,9.29,0,14,0l-.1.63-6.55,22.52c-6.74,1.79-15.32,3.25-21.92.19-3.67-2.33-4.43-6.79-3.77-10.82l.57-2.23,5.65-19.32C90,111.66,93.17,106,98.68,104.24a31.08,31.08,0,0,1,15.8.44,8.07,8.07,0,0,1,3.2,2.13Z"
          transform="translate(-0.42 -93.22)"
        />
        <path
          style={{ fill: '#ffffff' }}
          d="M47.17,114.23a4.21,4.21,0,0,1,1,4l-2.31,7.86c-2.92.15-5.7-.05-8.53,0,.43-1.69,1-3.3,1.42-5.05a2.93,2.93,0,0,0-1.46-2.61,5.08,5.08,0,0,0-5.43.87,8.17,8.17,0,0,0-3,6.55A4.06,4.06,0,0,0,30,128.36c2.78,2.61,5.75,5,8.62,7.57a8,8,0,0,1,2,6.94c-.61,5.72-3.77,11.59-9.19,14-5,2.28-11.68,3.11-16.92.72A7.64,7.64,0,0,1,10.6,150c.52-2.62,1.46-5.09,2.07-7.61,2.59-.05,5.76,0,8.63,0-.33,2.52-2.78,6,.09,8,1.75,1.31,4.25.49,5.89-.48a10.47,10.47,0,0,0,3.87-6.46c.28-2.62-2.55-3.49-3.91-5.19-3-3.15-7.92-5.24-8.11-10.14a20.71,20.71,0,0,1,4.86-13c5-5.19,13.76-5.63,20.12-3a8.72,8.72,0,0,1,3.06,2Z"
          transform="translate(-0.42 -93.22)"
        />
        <path
          style={{ fill: '#ffffff' }}
          d="M71.35,111.81c3.39.29,6.93,1.36,8.53,4.8,1.56,3.16.71,7-.14,10.29l-6.93,23.58a11.17,11.17,0,0,1-10.22,7.19h-20l.14-.59,5.6-19.41,7.54-25.91,15.51,0Zm-8.53,7.42-.09.1-8.87,30.33.1.14c2.59,0,5.14.15,7.92,0a4.59,4.59,0,0,0,2.4-1.5c1.28-2.13,1.6-4.56,2.36-6.89l4.85-16.5c.28-1.07.8-2.28.33-3.4a3.56,3.56,0,0,0-2.54-2.18l-6.46-.1Z"
          transform="translate(-0.42 -93.22)"
        />
        <path
          style={{ fill: '#ffffff' }}
          d="M143.74,125.93c-.61,2.52-1.41,5.1-2.17,7.67-4.61,0-9,.09-13.52,0-1.13,3.55-2.17,7.09-3.16,10.68l.19.1h12.58l.05.1c-.8,2.66-1.55,5.43-2.4,8.14l-12.78.06c-1.32,4.07-2.45,8.05-3.58,12.13l0,.1c4.76.09,9.47,0,14.28.09l-2.22,7.86-1.74.1H107.41l.14-.92,7-24.12,6.27-22,.09,0h22.86Z"
          transform="translate(-0.42 -93.22)"
        />
        <path
          style={{ fill: '#ffffff' }}
          d="M100.57,174.56c-1.84,4.66-6.41,8.49-11.31,9l-2.88.19-86-.07,6.22-20.89c4.1-.14,87.19,0,87.19,0,5.42-.1,10.65-4,12.68-9.22l-5.94,21Z"
          transform="translate(-0.42 -93.22)"
        />
        <path
          style={{ fill: '#ffffff' }}
          d="M166.15,151.71l2.16,6.44h-4.56l2.4-6.44Zm-2.9,8h5.62l1.49,4.37h2.11l-5.15-14.67H165l-5.33,14.67h2l1.57-4.37Z"
          transform="translate(-0.42 -93.22)"
        />
        <path
          style={{ fill: '#ffffff' }}
          d="M225,118.58a21.53,21.53,0,0,1-.13,19.73,13.55,13.55,0,0,0,3.38-7.29,9.1,9.1,0,0,1,.77,2.43,11.62,11.62,0,0,0-.1-8.25s3.88,6-.35,11.34a12.79,12.79,0,0,0-.27-1.49,19.37,19.37,0,0,1-4.62,5.34c4.78-1.34,7-3.7,7.91-6.83,2.4-7.89-6.59-15-6.59-15Z"
          transform="translate(-0.42 -93.22)"
        />
        <path
          style={{ fill: '#ffffff' }}
          d="M231.08,138.18a.19.19,0,0,0-.25,0c-.11.1-5.57,5.94-16.08,4.53A73.92,73.92,0,0,1,206,140.9c.76-1.55,1.31-3.76-.95-5-3.23-1.82-7.63,1.33-7.88,1.76-.09.16,0,.25.18.26.56.06,4.9-.06,6.77,2.56-4.61-1.14-5.17.83-4.49,2.86a25,25,0,0,0,3,5.65.22.22,0,0,1,0,.26.52.52,0,0,1-.22.14c-7.29,2.44-12.7,9.64-16.15,15.8a23.13,23.13,0,0,1-2.61-10.68,23.41,23.41,0,0,1,23.49-23.34,24,24,0,0,1,7.14,1.09l0-.05a24.8,24.8,0,0,0-33.64,23.13,24.6,24.6,0,0,0,3.84,13.23,61.26,61.26,0,0,0-2.76,6.56c-.31,1-.36,1.75.23,2s1-.07,1.83-1.24c2.69-3.69,14.1-20.63,22-15.27,3.94,2.66,5.18,5.13,2.88,7.44a26.07,26.07,0,0,1-5.85,3.75c-2.69,1.28-4.15,1.53-3.93,2.48s1.34.87,5.88-.3c5.22-1.35,17.06-4.43,10.95-14.47-2.77-4.54-5.6-8-7.5-10.78a.21.21,0,0,1,0-.24c.07-.09.24-.07.24-.07,20.12-1.5,22.57-9.64,22.64-9.92,0-.05.09-.23,0-.31Z"
          transform="translate(-0.42 -93.22)"
        />
        <path
          style={{ fill: '#ffffff' }}
          d="M228.29,164.56a23.58,23.58,0,0,1-38,6.16c-.7.86-1.36,1.71-2,2.49a24.77,24.77,0,0,0,40.06-8.63l-.06,0Z"
          transform="translate(-0.42 -93.22)"
        />
        <path
          style={{ fill: '#ffffff' }}
          d="M247,148.12h-.63l-.85-2.44a6,6,0,0,0-3.17-.75c-2.13,0-3.76.9-3.76,3.32,0,2.1,1.91,3.29,4.36,4.27s5.55,2.54,5.55,6.45-2.85,5.9-6.77,5.9a10.48,10.48,0,0,1-5.62-1.69v-3.39h.76l.69,2.63a6.8,6.8,0,0,0,4.32,1.39c2.76,0,4.33-1.51,4.33-4.12,0-1.84-1.22-3.28-4.23-4.51-2.79-1.13-5.68-2.51-5.68-6.18,0-2.92,2-5.14,6-5.14a11.29,11.29,0,0,1,4.67.94v3.32Z"
          transform="translate(-0.42 -93.22)"
        />
        <path
          style={{ fill: '#ffffff' }}
          d="M253.11,155.75c.29-2.64,1.54-4.9,3.49-4.9,2.38,0,3.07,3,3.07,4.9Zm-.06,1h9.16c.13-2.29-.75-6.8-5.55-6.8-3.61,0-6.21,3-6.21,7.37,0,4.23,2.66,7.46,7.06,7.46a7.89,7.89,0,0,0,4.26-1.26l-.31-.68a7,7,0,0,1-3.58.81c-3,0-4.8-2.91-4.83-6.9Z"
          transform="translate(-0.42 -93.22)"
        />
        <path
          style={{ fill: '#ffffff' }}
          d="M288.65,164.53h-4.73v-9.95c0-1.84-.38-3.38-2.17-3.38a6.28,6.28,0,0,0-4.2,2.16v10l1.78.53v.6h-4.17v-9.95c0-1.84-.43-3.38-2.1-3.38a6.4,6.4,0,0,0-4.3,2.16V163.5l1.76.43v.6h-6.33v-.6l2.19-.5V151.67l-2.57-.5v-.6a24.91,24.91,0,0,1,4.52-.22l.34,1.6a8.08,8.08,0,0,1,5.05-2,3.26,3.26,0,0,1,3.36,2.32,8.24,8.24,0,0,1,5.14-2.32c2.82,0,4.08,1.56,4.08,5.08v8.37l2.35.5v.6Z"
          transform="translate(-0.42 -93.22)"
        />
        <path
          style={{ fill: '#ffffff' }}
          d="M294.14,154c.75-1.57,2-2.76,3.55-2.76,2,0,3.1,2.07,3.1,6.09,0,3.85-1,6.46-4.05,6.46a5.14,5.14,0,0,1-2.6-.66V154Zm-.09-1.6a18.34,18.34,0,0,0-.44-2.07,17.67,17.67,0,0,0-4.43.22v.6l2.58.5V170l-2.35.53v.6h7.08v-.66l-2.35-.44v-5.84a6,6,0,0,0,2.76.63c3.45,0,6.59-2.63,6.59-7.43,0-3.92-1.32-7.4-5.05-7.4a5.45,5.45,0,0,0-4.39,2.44Z"
          transform="translate(-0.42 -93.22)"
        />
        <path
          style={{ fill: '#ffffff' }}
          d="M310,163.46l2.32.44v.63h-7.06v-.63l2.36-.47V151.67l-2.58-.5v-.6a17.36,17.36,0,0,1,4.39-.25,7.65,7.65,0,0,1,.51,1.88,6.19,6.19,0,0,1,4.67-2.22,3.66,3.66,0,0,1,1.19.15l-.91,1.94a2.63,2.63,0,0,0-1.28-.31,4.93,4.93,0,0,0-3.61,1.76v9.94Z"
          transform="translate(-0.42 -93.22)"
        />
        <path
          style={{ fill: '#ffffff' }}
          d="M325.19,161.05c-.72,1.72-1.94,2.54-3.1,2.54a2.83,2.83,0,0,1-2.76-3.08c0-2.44,1.81-3.67,4-3.67h1.85v4.21Zm4.58,2.67-2.19-.45v-8.53c0-3.57-2.42-4.76-5-4.76a8.6,8.6,0,0,0-5,1.53l.66,2.83.69-.13.16-2a4.64,4.64,0,0,1,3-1,2.79,2.79,0,0,1,3,3v1.57h-1.07c-3.48,0-7.24,1-7.24,5.18a4.11,4.11,0,0,0,4.33,3.85,4.84,4.84,0,0,0,4.08-2.25l.59,1.91a17.88,17.88,0,0,0,3.89-.19v-.59Z"
          transform="translate(-0.42 -93.22)"
        />
        <polygon
          style={{ fill: '#ffffff' }}
          points="349.87 68.64 349.05 71.31 335.84 71.31 335.84 70.59 338.04 70.15 338.04 51.95 335.84 51.49 335.84 50.77 348.26 50.77 349.08 53.4 348.42 53.68 347.42 51.99 340.58 51.99 340.58 59.76 347.23 59.76 347.23 60.99 340.58 60.99 340.58 70.09 348.17 70.09 349.2 68.36 349.87 68.64 349.87 68.64"
        />
        <path
          style={{ fill: '#ffffff' }}
          d="M367.41,164.53H362.8v-10c0-2.61-.72-3.39-2.39-3.39a6.53,6.53,0,0,0-4,2.16V163.5l1.76.43v.6h-6.34v-.6l2.19-.5V151.67l-2.57-.5v-.6a20,20,0,0,1,4.52-.25l.34,1.63a8.16,8.16,0,0,1,4.81-2c2.75,0,4.07,1.47,4.07,5v8.47l2.23.5v.6Z"
          transform="translate(-0.42 -93.22)"
        />
        <path
          style={{ fill: '#ffffff' }}
          d="M371.3,155.75c.28-2.64,1.53-4.9,3.48-4.9,2.38,0,3.08,3,3.08,4.9Zm-.06,1h9.15c.13-2.29-.75-6.8-5.55-6.8-3.6,0-6.21,3-6.21,7.37,0,4.23,2.67,7.46,7.06,7.46a7.92,7.92,0,0,0,4.27-1.26l-.32-.68a7,7,0,0,1-3.58.81c-3,0-4.79-2.91-4.82-6.9Z"
          transform="translate(-0.42 -93.22)"
        />
        <path
          style={{ fill: '#ffffff' }}
          d="M387,163.46l2.32.44v.63h-7v-.63l2.35-.47V151.67l-2.58-.5v-.6a17.38,17.38,0,0,1,4.39-.25,7.23,7.23,0,0,1,.51,1.88,6.19,6.19,0,0,1,4.67-2.22,3.66,3.66,0,0,1,1.19.15l-.91,1.94a2.63,2.63,0,0,0-1.28-.31,4.93,4.93,0,0,0-3.61,1.76v9.94Z"
          transform="translate(-0.42 -93.22)"
        />
        <path
          style={{ fill: '#ffffff' }}
          d="M396.08,154.84c0-2.23,1.47-3.8,3.2-3.8s3.13,1.57,3.13,3.8c0,2.88-1.63,4-3.13,4s-3.2-1.13-3.2-4ZM395,158.19a3.5,3.5,0,0,0-1.5,2.7A3.12,3.12,0,0,0,396,164a3.64,3.64,0,0,0-3,3.35c0,1.85,1.78,3.73,6.3,3.73,5,0,7.25-2.88,7.25-5.52a4.08,4.08,0,0,0-1.76-3.42h-6.24c-2.57,0-3.45-.94-3.45-2a1.85,1.85,0,0,1,.66-1.38,6.63,6.63,0,0,0,3.61,1.12c3.85,0,5.49-2.53,5.49-5a4.64,4.64,0,0,0-1.38-3.36l2.54-.47v-.85h-4.34a14.22,14.22,0,0,0-2.31-.24c-3.55,0-5.55,2.09-5.55,4.92a4.74,4.74,0,0,0,1.24,3.29Zm9,6.09a2.12,2.12,0,0,1,1,1.88c0,1.73-1.53,3.83-5,3.83s-4.61-1.45-4.61-2.89a3.15,3.15,0,0,1,1.92-2.82Z"
          transform="translate(-0.42 -93.22)"
        />
        <path
          style={{ fill: '#ffffff' }}
          d="M409.78,170.14a5.65,5.65,0,0,0,3-2.85l1.13-3.17-4.62-12.51-2.07-.69v-.6h6.09v.6l-1.54.53,3.29,9.69,3.83-10.82h3.29v.6l-2.41.65-5.52,15.19c-1,2.82-1.85,3.51-3.77,4.64l-.72-1.26Z"
          transform="translate(-0.42 -93.22)"
        />
        <path
          style={{ fill: '#ffffff' }}
          d="M438.66,164.36V162.8l-.05,0a3.52,3.52,0,0,1-3.4,1.9,3.15,3.15,0,0,1-3.47-3.31v-7.7h1.8v7.11c0,1.76.9,2.35,2.06,2.35,2.28,0,2.95-2,2.95-3.56v-5.9h1.8v10.71Z"
          transform="translate(-0.42 -93.22)"
        />
        <path
          style={{ fill: '#ffffff' }}
          d="M445.57,155.15v7c0,.84.71.84,1.08.84h.63v1.41c-.65.06-1.16.14-1.35.14-1.78,0-2.16-1-2.16-2.29v-7.06h-1.46v-1.5h1.46v-3h1.8v3h1.71v1.5Z"
          transform="translate(-0.42 -93.22)"
        />
        <path
          style={{ fill: '#ffffff' }}
          d="M449.51,151.71v-2.05h1.8v2.05Zm1.8,12.65h-1.8V153.65h1.8Z"
          transform="translate(-0.42 -93.22)"
        />
        <path
          style={{ fill: '#ffffff' }}
          d="M456,164.36h-1.8v-14.7H456Z"
          transform="translate(-0.42 -93.22)"
        />
        <path
          style={{ fill: '#ffffff' }}
          d="M459,151.71v-2.05h1.8v2.05Zm1.8,12.65H459V153.65h1.8Z"
          transform="translate(-0.42 -93.22)"
        />
        <path
          style={{ fill: '#ffffff' }}
          d="M465.84,155.15v7c0,.84.71.84,1.08.84h.64v1.41c-.66.06-1.17.14-1.36.14-1.78,0-2.16-1-2.16-2.29v-7.06h-1.46v-1.5H464v-3h1.8v3h1.72v1.5Z"
          transform="translate(-0.42 -93.22)"
        />
        <path
          style={{ fill: '#ffffff' }}
          d="M476.44,153.65h2c-1.25,3.5-2.47,7-3.85,10.5-1.69,4.34-1.88,4.59-4.19,4.59a2.72,2.72,0,0,1-.76-.16v-1.65a3,3,0,0,0,1.08.19c.95,0,1.05-.27,1.91-2.48l-4-11h2.05l2.84,8.72h0Z"
          transform="translate(-0.42 -93.22)"
        />
        <path
          style={{ fill: '#ffffff' }}
          d="M484,152.05a4.11,4.11,0,1,1,4.11-4.11A4,4,0,0,1,484,152.05Zm0-7.69a3.59,3.59,0,1,0,3.55,3.58A3.45,3.45,0,0,0,484,144.36Zm.77,3.83,1,1.95h-.7l-1-1.9h-1v1.9h-.6V145.6h1.84a1.28,1.28,0,0,1,1.46,1.31A1.25,1.25,0,0,1,484.78,148.19Zm-.44-2.06h-1.21v1.54h1.14c.65,0,1-.24,1-.76S484.91,146.13,484.34,146.13Z"
          transform="translate(-0.42 -93.22)"
        />
      </svg>
    );
    const socalLogo = (
      <svg
        viewBox="0 0 391.66666 75.653336"
        height="75.653336"
        width="391.66666"
        id="svg2"
        version="1.1"
        style={{ width: '100%', height: '100%', maxWidth: '250px' }}
      >
        <g transform="matrix(1.3333333,0,0,-1.3333333,0,75.653333)" id="g10">
          <g transform="scale(0.1)" id="g12">
            <path
              id="path14"
              style={{ fill: '#ffffff' }}
              d="M 0,567.422 H 305.426 V 9.35547 h -92.988 c 17.488,58.63283 16.523,130.51553 4.941,191.38653 -4.805,25.238 -10.09,50.512 -16.934,75.281 l -0.332,0.61 -0.976,-2.934 C 176.898,191.895 161.801,93.6914 187.457,9.35547 H 161.703 C 136.617,44.9805 118.48,84.6953 111.449,127.766 c -2.988,23.699 -3.398,50.769 -0.965,74.976 7.996,65.641 29.883,125.555 54.102,184.446 10.918,26.5 24.727,50.625 36.766,77 l 0.078,78.046 C 152.133,456.676 107.055,367.09 81.9102,270.859 66.2148,210.648 61,137.488 81.0898,77.9102 88.9688,54.1797 99.7695,30.7813 113.59,9.35547 H 0 V 567.422"
            />
            <path
              id="path16"
              style={{ fill: '#ffffff' }}
              d="m 486.188,210.938 v 6.406 c 0,21.308 -2.11,30.133 -10.313,40.746 -9.445,11.59 -27.668,18.238 -51.113,18.238 -39.817,0 -62.969,-21.558 -62.969,-58.664 0,-15.203 4.871,-27.391 13.992,-35.926 7.61,-6.679 21.016,-15.215 35.586,-22.793 30.129,-15.547 35.285,-20.683 35.285,-37.109 0,-15.203 -7.91,-23.6837 -21.613,-23.6837 -16.688,0 -21.277,7.5237 -21.883,35.4997 h -43.176 c 0,-24.875 1.536,-32.773 7.305,-42.8825 8.496,-14.8632 29.211,-23.0742 55.938,-23.0742 21.906,0 40.168,5.1953 50.519,14.2852 9.734,8.789 15.527,25.5395 15.527,44.7385 0,26.797 -9.757,41.328 -37.101,55.976 l -17.988,9.082 c -20.664,10.957 -28.868,20.403 -28.868,33.477 0,12.812 7.286,20.664 19.172,20.664 14.258,0 18.535,-7.852 18.848,-34.98 h 42.852"
            />
            <path
              id="path18"
              style={{ fill: '#ffffff' }}
              d="m 618.949,146.152 c 0,14.922 -1.219,29.192 -3.301,37.403 -4.609,19.492 -21.648,28.925 -52.664,28.925 -18.261,0 -30.714,-3.667 -39.211,-11.25 -9.441,-8.535 -13.406,-23.769 -13.406,-51.992 l 0.332,-4.277 0.262,-17.371 c 0.324,-28.0002 2.453,-37.0822 10.969,-46.2228 8.222,-8.4766 21.855,-12.5195 42.265,-12.5195 18.602,0 30.145,2.7734 40.145,10.7031 10.351,8.2422 13.976,18.8476 14.297,44.4332 z m -69.031,-0.261 v 16.714 c 0,18.528 4.258,25.84 14.277,25.84 13.407,0 15.496,-6.98 15.496,-52.066 v -9.082 l -0.593,-10.949 c -0.586,-18.2035 -3.926,-23.6917 -14.571,-23.6917 -11.574,0 -14.609,6.6679 -14.609,31.9337 v 2.129 19.172"
            />
            <path
              id="path20"
              style={{ fill: '#ffffff' }}
              d="m 730.855,138.262 c -1.183,-32.227 -5.46,-39.512 -22.832,-39.512 -8.8,0 -15.48,3.914 -17.941,10.918 -3.027,7.898 -4.559,26.777 -4.559,60.527 0,21.016 0.606,36.2 1.532,49.293 1.203,17.621 8.222,25.239 22.5,25.239 14.004,0 20.41,-7.618 20.656,-24.922 0.332,-2.75 0.332,-6.703 0.644,-11.883 h 41.942 v 11.566 c 0,9.731 -1.172,17.938 -2.996,24.969 -6.106,19.777 -28.633,31.906 -59.629,31.906 -20.684,0 -37.422,-5.179 -48.965,-14.875 -14.902,-12.832 -18.574,-30.148 -18.574,-90.093 0,-23.133 0.918,-44.676 2.148,-56.864 3.653,-31.3279 24.637,-46.8357 63.555,-46.8357 22.812,0 41.648,5.4883 51.414,15.1953 9.699,9.4922 12.766,22.2264 13.047,55.3714 h -41.942"
            />
            <path
              id="path22"
              style={{ fill: '#ffffff' }}
              d="m 833.473,169.91 c 0.312,17.641 2.097,21.301 11.855,21.301 10.027,0 12.469,-4.277 12.469,-21.301 v -11.57 c -6.387,0.312 -9.442,0.312 -12.774,0.312 -39.246,0 -52.937,-12.5 -52.937,-48.984 0,-27.9805 10.371,-40.7539 31.98,-40.7539 13.704,0 23.106,4.5429 33.731,17.3359 l 0.586,-15.5195 h 38.945 c -1.211,11.2812 -1.523,15.832 -1.523,21.3008 v 9.7457 56.563 c 0,25.879 -1.512,33.144 -8.504,41.367 -6.692,8.215 -21.613,12.758 -40.774,12.758 -15.214,0 -27.988,-2.695 -37.41,-8.207 -10.637,-6.035 -13.39,-13.047 -13.672,-34.348 z m 24.324,-32.508 V 120 c 0,-20.1055 -4.246,-27.6172 -15.234,-27.6172 -9.09,0 -12.469,5.3906 -12.469,20.3122 0,19.825 4.277,24.707 21.316,24.707 h 6.387"
            />
            <path
              id="path24"
              style={{ fill: '#ffffff' }}
              d="M 960.016,273.301 H 921.383 V 70.7305 h 38.633 V 273.301"
            />
            <path
              id="path26"
              style={{ fill: '#ffffff' }}
              d="m 1052.53,178.184 v -31.707 h 21.92 v -46.196 c -8.84,-1.2654 -12.82,-1.5115 -19.77,-1.5115 -13.99,0 -20.71,3.6135 -24.06,13.0465 -2.72,7.286 -3.36,18.848 -3.36,59.036 0,10.054 0.32,18.25 0.64,25.515 l 0.62,16.438 c 0.89,25.261 6.98,33.75 24.32,33.75 14.9,0 21.27,-7.297 21.27,-24.653 l 0.34,-10.293 h 41.92 c -0.25,20.071 -0.57,21.567 -2.7,30.996 -4.9,22.215 -26.12,33.813 -62.65,33.813 -10.33,0 -20.1,-1.227 -28.33,-3.344 -18.22,-5.5 -32.217,-22.246 -35.264,-42.972 -1.817,-13.934 -3.367,-41.918 -3.367,-66.235 0,-29.211 0.625,-43.203 3.367,-56.015 4.855,-26.4965 27.094,-40.1762 64.184,-40.1762 21.87,0 41.99,3.0469 64.76,8.8476 V 178.184 h -63.84"
            />
            <path
              id="path28"
              style={{ fill: '#ffffff' }}
              d="m 1181.28,169.922 c 0.32,17.644 2.17,21.25 11.92,21.25 10.01,0 12.44,-4.199 12.44,-21.25 v -11.524 c -6.43,0.254 -9.45,0.254 -12.77,0.254 -39.27,0 -52.97,-12.5 -52.97,-48.984 0,-27.9805 10.39,-40.7227 32,-40.7227 13.68,0 23.08,4.5781 33.74,17.3047 l 0.61,-15.5195 h 38.93 c -1.17,11.25 -1.53,15.8398 -1.53,21.332 v 9.7145 56.621 c 0,25.84 -1.5,33.118 -8.52,41.309 -6.68,8.258 -21.61,12.793 -40.72,12.793 -15.22,0 -27.99,-2.766 -37.45,-8.203 -10.62,-6.094 -13.4,-13.125 -13.69,-34.375 z m 24.36,-32.559 v -17.324 c 0,-20.0898 -4.28,-27.7148 -15.21,-27.7148 -9.15,0 -12.47,5.4961 -12.47,20.3708 0,19.825 4.22,24.668 21.25,24.668 h 6.43"
            />
            <path
              id="path30"
              style={{ fill: '#ffffff' }}
              d="m 1365.06,169.297 c 0,16.125 -1.8,22.558 -8.49,30.078 -7.91,8.84 -20.65,13.125 -39.54,13.125 -32.55,0 -51.11,-14.297 -51.11,-39.238 0,-16.114 7.86,-26.426 28.02,-36.504 l 10.29,-4.863 c 21.29,-10.665 25.57,-14.922 25.57,-25.293 0,-8.7895 -5.44,-14.8832 -13.68,-14.8832 -9.46,0 -12.8,5.2148 -13.09,18.5942 0,1.503 0,3.664 -0.28,6.375 h -37.77 v -3.7 c 0,-30.4372 16.78,-44.0427 52.96,-44.0427 31.28,0 50.48,15.7617 50.48,42.2847 0,10.293 -3.64,19.688 -10.3,26.458 -6.09,6.074 -14.34,11.218 -29.52,18.835 l -8.5,4.266 c -12.19,6.086 -16.46,10.949 -16.46,18.254 0,7.93 4.27,12.129 11.86,12.129 8.2,0 11.87,-4.512 11.87,-15.469 0,-1.523 0.33,-3.633 0.6,-6.406 h 37.09"
            />
            <path
              id="path32"
              style={{ fill: '#ffffff' }}
              d="m 1469.1,91.7188 h 25.37 l 6.72,-19.6993 h 9.51 l -23.22,66.2225 h -10.47 l -24.05,-66.2225 h 9.06 z m 13.1,36.1132 9.73,-29.0039 h -20.56 z"
            />
            <path
              id="path34"
              style={{ fill: '#ffffff' }}
              d="m 1747.77,277.32 c 20.27,-39.972 6.4,-75.367 -0.59,-89 14.27,16.7 15.25,32.856 15.25,32.856 0,0 2.6,-5.145 3.44,-10.91 7.38,20.257 -0.44,37.211 -0.44,37.211 0,0 17.5,-27.161 -1.57,-51.168 -0.44,3.75 -1.24,6.699 -1.24,6.699 -3.81,-7.07 -11.95,-17.09 -20.84,-24.063 21.58,6.055 31.43,16.7 35.7,30.789 10.83,35.602 -29.71,67.586 -29.71,67.586"
            />
            <path
              id="path36"
              style={{ fill: '#ffffff' }}
              d="m 1775.07,188.895 c -0.42,0.304 -0.91,0.109 -1.11,-0.086 -0.48,-0.489 -25.16,-26.797 -72.53,-20.489 -15.47,2.051 -28.77,5.645 -39.43,8.34 3.44,6.953 5.93,16.914 -4.27,22.703 -14.59,8.227 -34.43,-6.004 -35.56,-7.925 -0.42,-0.715 0.17,-1.125 0.78,-1.204 2.55,-0.214 22.16,0.325 30.55,-11.523 -20.8,5.137 -23.29,-3.738 -20.23,-12.891 1.8,-5.488 6.49,-15.605 13.29,-25.488 0,0 0.46,-0.644 0.02,-1.191 -0.26,-0.313 -0.66,-0.508 -1.03,-0.625 -32.87,-11.016 -57.25,-43.4769 -72.82,-71.2894 -7.51,14.4609 -11.78,30.8398 -11.78,48.2424 0,58.152 47.46,105.23 105.98,105.23 11.26,0 22.04,-1.73 32.22,-4.922 l 0.07,0.235 c -12.43,4.754 -25.94,7.383 -40.04,7.383 -61.69,0 -111.7,-50.016 -111.7,-111.707 0,-21.9419 6.35,-42.4497 17.29,-59.7075 -7.94,-16.3555 -12.13,-28.6407 -12.41,-29.5977 -1.42,-4.2969 -1.65,-7.8633 1,-9.0625 2.37,-1.0547 4.44,0.293 8.27,5.5352 12.11,16.7109 63.61,93.0785 99.36,68.9375 17.78,-12.0313 23.39,-23.2032 13.01,-33.5742 -5.46,-5.4961 -18.52,-13.2227 -26.36,-16.9063 -12.19,-5.7695 -18.78,-6.9219 -17.76,-11.1992 0.93,-3.9453 6.03,-3.9453 26.52,1.3476 23.53,6.0938 76.96,20.0196 49.39,65.3125 -12.48,20.4496 -25.24,35.9176 -33.81,48.6056 -0.24,0.34 -0.32,0.672 -0.05,1.043 0.29,0.43 1.06,0.332 1.06,0.332 90.75,6.758 101.82,43.535 102.15,44.746 0.07,0.234 0.38,1.023 -0.07,1.395"
            />
            <path
              id="path38"
              style={{ fill: '#ffffff' }}
              d="m 1762.5,69.9219 c -17.08,-35.3321 -53.45,-59.7266 -95.57,-59.7266 -29.83,0 -56.76,12.207 -76.03,31.9336 -3.18,-3.9141 -6.14,-7.7266 -8.83,-11.2695 C 1602.13,11.7773 1629.3,0 1659.18,0 c 46.88,0 87.02,28.8555 103.58,69.8047 l -0.26,0.1172"
            />
            <path
              id="path40"
              style={{ fill: '#ffffff' }}
              d="m 1846.88,144.043 h -2.82 l -3.82,11.055 c -2.4,1.836 -8.18,3.379 -14.3,3.379 -9.61,0 -16.98,-4.082 -16.98,-14.969 0,-9.492 8.63,-14.887 19.68,-19.27 11.46,-4.543 25.06,-11.457 25.06,-29.1403 0,-17.2461 -12.88,-26.6016 -30.57,-26.6016 -12.47,0 -22.35,5.5273 -25.33,7.6172 v 15.293 h 3.39 l 3.11,-11.8555 c 3.67,-3.2813 11.04,-6.25 19.52,-6.25 12.46,0 19.53,6.8047 19.53,18.5547 0,8.3395 -5.51,14.8245 -19.09,20.3785 -12.61,5.11 -25.63,11.301 -25.63,27.844 0,13.184 9.06,23.223 27.18,23.223 9.91,0 16.99,-2.41 21.07,-4.238 v -15.02"
            />
            <path
              id="path42"
              style={{ fill: '#ffffff' }}
              d="m 1874.2,105 h 41.3 c 0.59,10.332 -3.38,30.703 -25.03,30.703 -16.27,0 -28.01,-13.32 -28.01,-33.262 0,-19.0894 12.03,-33.6637 31.82,-33.6637 6.65,0 13.03,1.8204 19.25,5.6563 l -1.41,3.1172 c -6.11,-3.1172 -10.33,-3.6836 -16.14,-3.6836 -13.56,0 -21.65,13.1445 -21.78,31.1328 m 0.29,4.668 c 1.27,11.875 6.92,22.059 15.69,22.059 10.75,0 13.87,-13.719 13.87,-22.059 h -29.56"
            />
            <path
              id="path44"
              style={{ fill: '#ffffff' }}
              d="m 2034.78,70.0391 h -21.36 v 44.8949 c 0,8.32 -1.7,15.261 -9.76,15.261 -4.93,0 -13.57,-3.398 -18.95,-9.765 V 75.1563 l 8.05,-2.4141 v -2.7031 h -18.81 v 44.8949 c 0,8.32 -1.99,15.261 -9.48,15.261 -5.66,0 -14.72,-3.965 -19.4,-9.765 V 74.7266 l 7.93,-1.9844 v -2.7031 h -28.57 v 2.7031 L 1934.3,75 v 53.086 l -11.58,2.246 v 2.664 c 7.5,0.852 12.47,1.438 20.38,1.02 l 1.56,-7.219 c 7.06,5.808 15.13,8.906 22.78,8.906 8.48,0 13.17,-4.519 15.14,-10.476 5.36,5.261 15,10.476 23.2,10.476 12.73,0 18.38,-7.082 18.38,-22.922 V 75 l 10.62,-2.2578 v -2.7031"
            />
            <path
              id="path46"
              style={{ fill: '#ffffff' }}
              d="m 2059.13,124.695 c -0.43,3.489 -1.56,7.746 -1.99,9.321 -7.79,0.847 -12.45,-0.168 -19.96,-1.02 v -2.664 l 11.61,-2.246 V 45.4297 l -10.58,-2.3828 V 40.332 h 31.96 v 2.9688 l -10.62,1.9726 v 26.336 c 2.83,-1.5508 7.49,-2.8321 12.44,-2.8321 15.56,0 29.73,11.8672 29.73,33.5467 0,17.656 -5.96,33.379 -22.79,33.379 -6.94,0 -15.01,-3.808 -19.8,-11.008 m 0.42,-7.254 c 3.39,7.09 9.06,12.454 16,12.454 8.92,0 14.01,-9.309 14.01,-27.454 0,-17.4019 -4.68,-29.1402 -18.27,-29.1402 -2.54,0 -7.66,0.4297 -11.74,2.9687 v 41.1715"
            />
            <path
              id="path48"
              style={{ fill: '#ffffff' }}
              d="m 2131,74.8711 10.47,-1.9805 v -2.8515 h -31.84 v 2.8515 L 2120.24,75 v 53.066 l -11.59,2.266 v 2.676 c 7.52,0.84 12.02,1.98 19.78,1.152 0.58,-1.863 1.98,-4.812 2.3,-8.476 5.51,5.742 11.86,10.019 21.07,10.019 1.98,0 3.81,-0.156 5.39,-0.715 l -4.12,-8.758 c -0.69,0.411 -2.84,1.407 -5.8,1.407 -4.1,0 -10.04,-1.407 -16.27,-7.91 V 74.8711"
            />
            <path
              id="path50"
              style={{ fill: '#ffffff' }}
              d="m 2220.27,73.7305 -9.89,1.9726 V 114.18 c 0,16.152 -10.92,21.523 -22.39,21.523 -8.62,0 -17.52,-3.098 -22.32,-6.933 l 2.96,-12.754 3.11,0.547 0.73,9.226 c 2.4,2.395 7.9,4.406 13.42,4.406 6.93,0 13.72,-3.554 13.72,-13.73 v -7.09 h -4.8 c -15.71,0 -32.71,-4.641 -32.71,-23.3398 0,-10.3321 9.53,-17.4024 19.55,-17.4024 6.78,0 13.72,3.1055 18.39,10.207 l 2.7,-8.6445 c 0,0 7.51,-0.7031 17.53,0.8516 z m -20.66,12.0508 c -3.25,-7.8204 -8.75,-11.504 -13.99,-11.504 -6.52,0 -12.44,5.1094 -12.44,13.875 0,11.0079 8.17,16.5817 18.08,16.5817 h 8.35 V 85.7813"
            />
            <path
              id="path52"
              style={{ fill: '#ffffff' }}
              d="m 2312.84,82.0508 -3.7,-12.0117 h -59.56 v 3.2617 l 9.89,1.9726 v 82.0706 l -9.89,2.148 v 3.223 h 56.02 l 3.69,-11.875 -2.98,-1.27 -4.53,7.637 h -30.87 v -35.078 h 30 v -5.566 h -30 V 75.5664 h 34.24 l 4.68,7.8125 3.01,-1.3281"
            />
            <path
              id="path54"
              style={{ fill: '#ffffff' }}
              d="m 2390.07,70.0391 h -20.8 v 44.9729 c 0,11.785 -3.24,15.32 -10.78,15.32 -4.24,0 -13.54,-4.102 -18.09,-9.746 V 74.7266 l 7.91,-1.9844 v -2.7031 h -28.57 v 2.7031 L 2329.62,75 v 53.086 l -11.57,2.246 v 2.664 c 7.51,0.852 12.57,1.867 20.38,1.145 l 1.53,-7.344 c 4.82,4.238 13.72,8.906 21.69,8.906 12.44,0 18.37,-6.672 18.37,-22.512 V 75 l 10.05,-2.2578 v -2.7031"
            />
            <path
              id="path56"
              style={{ fill: '#ffffff' }}
              d="m 2407.32,105 h 41.3 c 0.56,10.332 -3.38,30.703 -25.03,30.703 -16.27,0 -28.01,-13.32 -28.01,-33.262 0,-19.0894 12.03,-33.6637 31.83,-33.6637 6.65,0 13.04,1.8204 19.24,5.6563 l -1.41,3.1172 c -6.09,-3.1172 -10.33,-3.6836 -16.11,-3.6836 -13.59,0 -21.65,13.1445 -21.81,31.1328 m 0.29,4.668 c 1.25,11.875 6.93,22.059 15.7,22.059 10.75,0 13.89,-13.719 13.89,-22.059 h -29.59"
            />
            <path
              id="path58"
              style={{ fill: '#ffffff' }}
              d="m 2478.23,74.8711 10.45,-1.9805 v -2.8515 h -31.83 v 2.8515 L 2467.48,75 v 53.066 l -11.64,2.266 v 2.676 c 7.51,0.84 12.06,1.98 19.84,1.152 0.56,-1.863 1.97,-4.812 2.24,-8.476 5.55,5.742 11.92,10.019 21.1,10.019 1.96,0 3.83,-0.156 5.35,-0.715 l -4.08,-8.758 c -0.68,0.411 -2.81,1.407 -5.81,1.407 -4.08,0 -10.06,-1.407 -16.25,-7.91 V 74.8711"
            />
            <path
              id="path60"
              style={{ fill: '#ffffff' }}
              d="m 2554.9,71.1914 c 2.84,-1.6992 4.38,-4.9609 4.38,-8.5156 0,-7.793 -6.92,-17.2266 -22.63,-17.2266 -15.69,0 -20.81,6.4844 -20.81,13.0078 0,5.4883 3.97,11.0352 8.65,12.7344 z m -40.48,27.4297 c -4.07,-2.957 -6.78,-7.6133 -6.78,-12.1758 0,-6.2031 3.7,-12.168 11.16,-14.0039 -8.45,-2.1094 -13.72,-8.3476 -13.72,-15.1172 0,-8.3476 8.07,-16.8554 28.45,-16.8554 22.32,0 32.68,13.0078 32.68,24.9414 0,6.0664 -2.69,11.8476 -7.93,15.4101 h -28.12 c -11.62,0 -15.6,4.2188 -15.6,9.043 0,2.4023 1.28,4.8164 2.98,6.1914 5.25,-3.6524 11.77,-5.0469 16.27,-5.0469 17.41,0 24.78,11.4332 24.78,22.4692 0,5.8 -2.12,11.054 -6.23,15.144 l 11.46,2.141 v 3.828 h -19.54 c -2.64,0.547 -7.48,1.113 -10.47,1.113 -15.96,0 -25.02,-9.473 -25.02,-22.226 0,-6.504 2.42,-11.309 5.63,-14.8559 m 4.98,15.1489 c 0,10.058 6.66,17.128 14.41,17.128 7.93,0 14.13,-7.07 14.13,-17.128 0,-13 -7.33,-17.9692 -14.13,-17.9692 -6.5,0 -14.41,5.0972 -14.41,17.9692"
            />
            <path
              id="path62"
              style={{ fill: '#ffffff' }}
              d="m 2581.21,44.7266 c 5.54,2.8007 11.35,6.6601 13.61,12.8789 l 5.1,14.2695 -20.83,56.465 -9.36,3.086 v 2.715 h 27.49 v -2.715 l -6.97,-2.383 14.86,-43.711 17.29,48.809 h 14.83 v -2.715 l -10.85,-2.942 -24.92,-68.4449 c -4.69,-12.7735 -8.36,-15.8985 -16.98,-20.9571 l -3.27,5.6446"
            />
            <path
              id="path64"
              style={{ fill: '#ffffff' }}
              d="m 2715.94,70.8281 v 7.0235 l -0.22,0.1757 c -3.41,-5.625 -7.59,-8.5937 -15.32,-8.5937 -7.09,0 -15.71,3.4375 -15.71,14.9609 v 34.7075 h 8.15 V 87.0625 c 0,-7.9219 4.08,-10.6094 9.29,-10.6094 10.28,0 13.3,9.0352 13.3,16.1055 v 26.5434 h 8.16 V 70.8281 h -7.65"
            />
            <path
              id="path66"
              style={{ fill: '#ffffff' }}
              d="M 2747.08,112.363 V 80.9766 c 0,-3.7891 3.24,-3.7891 4.89,-3.7891 h 2.88 v -6.3594 c -2.97,-0.3086 -5.27,-0.6523 -6.1,-0.6523 -8.03,0 -9.79,4.5508 -9.79,10.332 v 31.8552 h -6.55 v 6.739 h 6.55 v 13.503 h 8.12 v -13.503 h 7.77 v -6.739 h -7.77"
            />
            <path
              id="path68"
              style={{ fill: '#ffffff' }}
              d="m 2773,70.8281 h -8.08 v 48.2739 h 8.08 z m -8.08,66.3009 h 8.08 v -9.219 h -8.08 v 9.219"
            />
            <path
              id="path70"
              style={{ fill: '#ffffff' }}
              d="m 2794.28,70.8281 h -8.11 v 66.2889 h 8.11 V 70.8281"
            />
            <path
              id="path72"
              style={{ fill: '#ffffff' }}
              d="m 2815.6,70.8281 h -8.15 v 48.2739 h 8.15 z m -8.15,66.3009 h 8.15 v -9.219 h -8.15 v 9.219"
            />
            <path
              id="path74"
              style={{ fill: '#ffffff' }}
              d="M 2838.54,112.363 V 80.9766 c 0,-3.7891 3.23,-3.7891 4.88,-3.7891 h 2.87 v -6.3594 c -2.96,-0.3086 -5.27,-0.6523 -6.1,-0.6523 -8.04,0 -9.77,4.5508 -9.77,10.332 v 31.8552 h -6.56 v 6.739 h 6.56 v 13.503 h 8.12 v -13.503 h 7.75 v -6.739 h -7.75"
            />
            <path
              id="path76"
              style={{ fill: '#ffffff' }}
              d="m 2886.35,119.102 h 8.96 c -5.64,-15.762 -11.16,-31.5825 -17.38,-47.3637 -7.62,-19.5899 -8.49,-20.6641 -18.91,-20.6641 -1.2,0 -2.14,0.3438 -3.41,0.7031 v 7.4219 c 1.03,-0.3906 2.68,-0.8594 4.88,-0.8594 4.23,0 4.72,1.2305 8.6,11.1993 l -17.91,49.5629 h 9.24 l 12.84,-39.3168 h 0.15 l 12.94,39.3168"
            />
            <path
              id="path78"
              style={{ fill: '#ffffff' }}
              d="m 2921.2,153.84 h -5.19 v -6.613 h 4.9 c 2.79,0 4.12,1.015 4.12,3.281 0,2.219 -1.36,3.332 -3.83,3.332 m 1.93,-8.899 4.37,-8.398 h -3.03 l -4.21,8.203 h -4.25 v -8.203 h -2.63 v 19.629 h 8.01 c 3.53,0 6.25,-1.785 6.25,-5.664 0,-2.969 -1.75,-4.91 -4.51,-5.567 m -3.32,16.563 c -8.71,0 -15.34,-6.328 -15.34,-15.469 0,-9.14 6.63,-15.476 15.34,-15.476 8.67,0 15.31,6.336 15.31,15.476 0,9.141 -6.64,15.469 -15.31,15.469 m 0,-33.203 c -10.09,0 -17.75,7.168 -17.75,17.734 0,10.567 7.66,17.735 17.75,17.735 10.05,0 17.69,-7.168 17.69,-17.735 0,-10.566 -7.64,-17.734 -17.69,-17.734"
            />
          </g>
        </g>
      </svg>
    );

    const sempraExtrenalLogo = (
      <svg
        viewBox="0 0 391.66666 75.653336"
        height="119.6"
        width="482.17334"
        xmlSpace="preserve"
        id="svg2"
        version="1.1"
        style={{ width: w, height: h }}
      >
        <path
          id="Wordmark"
          style={{ fill: '#001689' }}
          d="M227.14,57.51h9V44.43h7.59c10.53,0,17.34-5.9,17.34-15v-.12c0-9-6.44-14.79-16.41-14.79H227.14Zm16.91-21h-7.9V22.55h7.71c5.07,0,8.09,2.58,8.09,6.91v.12C252,33.71,248.77,36.48,244.05,36.48Zm50.89,21h10.5L294.88,42.09l.36-.14c5.9-2.19,9.15-6.89,9.15-13.24v-.13a13.43,13.43,0,0,0-3.65-9.76c-2.84-2.84-7.16-4.34-12.5-4.34H268.59v43h9V43.56h8Zm-7.33-21.84h-10V22.55h9.84c5,0,7.83,2.38,7.83,6.53v.13C295.26,33.2,292.33,35.67,287.61,35.67Zm54.94,21.84h9.38L333.49,14.17H325L306.59,57.51h9.13l4-9.78h18.85Zm-7.17-17.66H322.9l6.24-15.23Zm-246.17,12a27.67,27.67,0,0,0,18.3,6.38c9.69,0,15.72-5.12,15.72-13.36v-.12c0-7.71-5.21-10.61-13.61-12.8C102,30.05,100,29.08,100,26v-.13c0-2.11,1.83-4.23,5.91-4.23a20,20,0,0,1,11.27,3.71l4.65-6.55a24.9,24.9,0,0,0-15.8-5c-8.81,0-15,5.34-15,13v.13c0,8.74,5.9,10.92,14.24,13.05,7.07,1.81,9,3.07,9,5.83v.13c0,2.76-2.59,4.54-6.59,4.54a20.32,20.32,0,0,1-13.19-4.84Zm42.36,5.62h32.62V49.57H140.51V39.82h20.57V31.87H140.51V22.43h23.37V14.48H131.57Zm75.91,0h8.94v-43h-9.88L194.91,33.2,183.28,14.48h-9.89v43h8.82v-29l12.57,19.08,12.7-19.27Z"
        />
        <path
          id="Symbol"
          style={{ fill: '#ea0029' }}
          d="M38.25,9.91c2.74,0,5,1.95,5,4.36s-2.23,4.35-5,4.35-5-1.95-5-4.35S35.51,9.91,38.25,9.91Zm29.32,8.86a10.55,10.55,0,0,0,.88-2.33c0-.07.07-.33,0-.42a.24.24,0,0,0-.33,0c-20.21,15.23-40.56-4.23-38.53,8.38.26,1.63,1.86,4.56,3.8,7.4a.26.26,0,0,1,0,.33.64.64,0,0,1-.28.18c-10.54,5.58-17.52,14-21.79,20.75A30.05,30.05,0,0,1,58,15.5l.06.08.09-.05a29.21,29.21,0,0,0,4.93-3l.12-.09-.1-.11a36,36,0,0,0-55,46.37c-1.32,2.62-2,4.49-2.27,5.05C4.92,66,5.71,66.66,6,66.8c.65.26,1.6,0,2.56-1.27C15.3,57,33.43,36,42.41,42c5.09,3.44,4.93,7.3,1.1,10.84a37.44,37.44,0,0,1-7.57,4.85c-3.49,1.65-5.37,2-5.09,3.21s1.74,1.14,7.61-.39c6.75-1.75,21.43-6.8,15.3-19.06-3-6-13.14-10.87-13.14-11s.3-.1.3-.1c11.9-.89,18.64-3.8,22.46-6.74A30,30,0,0,1,19.15,60.88L19,60.8l-.08.12c-1,1.6-2,3.23-2.92,4.85l-.06.12.11.07A36,36,0,0,0,67.57,18.77Z"
        />
        <path
          style={{ fill: '#001689' }}
          d="M342.77,15.07H341v-.9h4.49v.9h-1.76v4.62h-1Z"
        />
        <path
          style={{ fill: '#001689' }}
          d="M346.51,14.17h1l1.68,2.61,1.68-2.61h1v5.52h-1v-4l-1.74,2.61h0l-1.73-2.59v3.94h-.95Z"
        />
      </svg>
    );
    const sdgeExternal = (
      <svg
        viewBox="0 0 351.34 206.76"
        height="119.6"
        width="482.17334"
        xmlSpace="preserve"
        id="svg2"
        version="1.1"
        style={{ width: ' 53px', height: '30px' }}
      >
        <path
          style={{ fill: '#ca003d' }}
          d="M354.69,3.93l-2.24,7.2L340.83,52H308.22c-12,.11-23.57,9-28.2,20.27l1.41-5.09,12.26-42.44c4.52-11.63,15.72-20,27.66-20.83Z"
          transform="translate(-3.35 -3.93)"
        />
        <path
          style={{ fill: '#231f20' }}
          d="M271.09,35a10.61,10.61,0,0,1,2.8,8.65l-5.16,18.28-19.15-.11c1.06-4.33,2.47-8.43,3.33-13a4,4,0,0,0-.43-2.21,13.41,13.41,0,0,0-13.34-3c-6.25,1.21-11.09,7.42-13.89,13l-2.15,5.21-11.63,40.44c-1.18,3.88-2.9,8.65-.1,12.3,4.73,5.76,13.23,5.65,19.58,3.76,1.72-.66,4-1,5-2.54,1.72-6.42,3.87-12.85,5.48-19.28l-.43-.22H227.09l4.73-17.06.31-.23c-.1-.1-.21-.1-.21-.33,10.33-.11,21.2,0,31.86,0l-.22,1.44-15,51.41c-15.39,4.1-35,7.43-50,.45-8.39-5.32-10.11-15.51-8.61-24.71l1.29-5.1L204.16,58c3.76-12,11-24.93,23.56-28.92a70.91,70.91,0,0,1,36.06,1A18.61,18.61,0,0,1,271.09,35Z"
          transform="translate(-3.35 -3.93)"
        />
        <path
          style={{ fill: '#231f20' }}
          d="M110.1,51.9a9.64,9.64,0,0,1,2.26,9.2l-5.27,17.95c-6.67.33-13-.11-19.48,0,1-3.87,2.27-7.54,3.24-11.52a6.67,6.67,0,0,0-3.34-6c-4-2-9-.89-12.38,2a18.64,18.64,0,0,0-6.78,15,9.29,9.29,0,0,0,2.48,5.65c6.35,6,13.13,11.41,19.69,17.29A18.21,18.21,0,0,1,95,117.28c-1.4,13.08-8.61,26.49-21,32-11.51,5.2-26.68,7.09-38.63,1.65a17.47,17.47,0,0,1-8.82-17.28c1.19-6,3.33-11.63,4.73-17.4,5.92-.11,13.14,0,19.7,0-.76,5.77-6.35,13.63.22,18.39,4,3,9.68,1.12,13.44-1.1,5-3.54,7.64-9.2,8.83-14.74.64-6-5.81-8-8.94-11.85-6.77-7.2-18.07-12-18.5-23.16,0-11,4.3-21.17,11.08-29.59,11.51-11.85,31.42-12.85,46-7a20.06,20.06,0,0,1,7,4.65Z"
          transform="translate(-3.35 -3.93)"
        />
        <path
          style={{ fill: '#231f20' }}
          d="M165.31,46.37c7.75.66,15.82,3.1,19.48,11,3.55,7.21,1.61,16.07-.32,23.5l-15.82,53.84a25.48,25.48,0,0,1-23.35,16.41H99.56l.32-1.33,12.81-44.32L129.9,46.26l35.41.11Zm-19.48,17-.21.22-20.24,69.25.22.34c5.92.1,11.73.32,18.08,0a10.52,10.52,0,0,0,5.49-3.44c2.91-4.87,3.65-10.41,5.38-15.73l11.08-37.68c.65-2.43,1.83-5.21.75-7.75a8.09,8.09,0,0,0-5.8-5l-14.75-.22Z"
          transform="translate(-3.35 -3.93)"
        />
        <path
          style={{ fill: '#231f20' }}
          d="M330.6,78.61c-1.4,5.76-3.22,11.64-5,17.51-10.54.11-20.66.22-30.88.11-2.58,8.09-5,16.18-7.21,24.37l.43.22h28.73l.11.24c-1.83,6.08-3.55,12.39-5.49,18.6l-29.16.11c-3,9.31-5.6,18.4-8.18,27.71l.1.22c10.87.22,21.63,0,32.61.22l-5.06,18-4,.22h-50L248,184l15.92-55.07L278.2,78.73l.21-.12H330.6Z"
          transform="translate(-3.35 -3.93)"
        />
        <path
          style={{ fill: '#ca003d' }}
          d="M232,189.64c-4.2,10.64-14.64,19.39-25.83,20.61l-6.56.44L3.35,210.52l14.21-47.7c9.36-.33,199.08,0,199.08,0,12.37-.22,24.32-9.2,29-21L232,189.64Z"
          transform="translate(-3.35 -3.93)"
        />
        <path
          style={{ fill: '#231f20' }}
          d="M329.4,168.22l-.29,1.59h-4.55l-2.5,14.13h-1.75l2.49-14.13h-4.6l.29-1.59Z"
          transform="translate(-3.35 -3.93)"
        />
        <path
          style={{ fill: '#231f20' }}
          d="M346.06,168.22l-2.79,15.72h-1.68l1.88-10.65.3-1.68c-.27.61-.9,1.77-1.4,2.61l-5.94,10.12-2.3-10.12c-.17-.81-.42-2-.47-2.61l-.29,1.68-1.88,10.65H329.8l2.79-15.72h1.91l2,9.41c.21,1,.52,2.4.59,3.05.31-.67,1.14-2.15,1.66-3.05l5.34-9.41Z"
          transform="translate(-3.35 -3.93)"
        />
      </svg>
    );
    const socalExternal = (
      <svg
        viewBox="0 0 237.71 93.35"
        height="119.6"
        width="482.17334"
        xmlSpace="preserve"
        id="svg2"
        version="1.1"
        style={{ width: ' 76px', height: '35px' }}
      >
        <path
          style={{ fill: '#004b90' }}
          d="M2.22,2H53.31V95.36H37.76c2.92-9.81,2.76-21.83.82-32-.8-4.22-1.68-8.45-2.83-12.59l0-.1-.17.49c-3.72,13.68-6.24,30.11-2,44.21H29.27a47.85,47.85,0,0,1-8.4-19.8A57.72,57.72,0,0,1,20.71,63c1.33-11,5-21,9.05-30.86,1.82-4.43,4.13-8.46,6.14-12.87l0-13.06c-8.25,14.32-15.79,29.3-20,45.39-2.63,10.08-3.5,22.31-.14,32.28a50.69,50.69,0,0,0,5.43,11.46h-19Z"
          transform="translate(-2.22 -2.02)"
        />
        <path
          style={{ fill: '#004b90' }}
          d="M83.55,61.65V60.58c0-3.57-.36-5-1.73-6.82-1.58-1.94-4.63-3-8.55-3-6.66,0-10.53,3.61-10.53,9.81a7.94,7.94,0,0,0,2.34,6,32.47,32.47,0,0,0,6,3.81c5,2.6,5.91,3.46,5.91,6.21,0,2.54-1.33,4-3.62,4-2.79,0-3.56-1.26-3.66-5.93H62.44c0,4.15.25,5.47,1.22,7.16,1.42,2.49,4.89,3.86,9.36,3.86,3.66,0,6.72-.86,8.45-2.38s2.59-4.28,2.59-7.48c0-4.49-1.63-6.92-6.2-9.37l-3-1.52C71.39,63,70,61.44,70,59.25s1.22-3.46,3.21-3.46c2.38,0,3.1,1.32,3.15,5.86Z"
          transform="translate(-2.22 -2.02)"
        />
        <path
          style={{ fill: '#004b90' }}
          d="M94.21,72.53v-2.8c0-3.1.71-4.32,2.39-4.32,2.24,0,2.59,1.17,2.59,8.71v1.51l-.1,1.84c-.1,3-.66,4-2.44,4-1.93,0-2.44-1.12-2.44-5.34V72.53Zm11.55,0a29,29,0,0,0-.56-6.26c-.77-3.26-3.62-4.83-8.81-4.83-3,0-5.13.61-6.55,1.88-1.58,1.43-2.25,4-2.25,8.7l.06.71,0,2.91c.06,4.68.41,6.2,1.84,7.73s3.65,2.09,7.07,2.09c3.11,0,5-.46,6.71-1.79s2.34-3.15,2.39-7.43Z"
          transform="translate(-2.22 -2.02)"
        />
        <path
          style={{ fill: '#004b90' }}
          d="M124.47,73.8c-.2,5.4-.91,6.61-3.82,6.61a2.92,2.92,0,0,1-3-1.83c-.5-1.31-.76-4.47-.76-10.12,0-3.51.1-6,.26-8.24.19-2.95,1.37-4.23,3.76-4.23s3.41,1.28,3.45,4.17c.06.46.06,1.12.11,2h7V60.22A16.79,16.79,0,0,0,131,56c-1-3.31-4.79-5.34-10-5.34-3.46,0-6.26.87-8.19,2.49-2.49,2.15-3.1,5-3.1,15.07,0,3.87.15,7.48.35,9.51.62,5.24,4.13,7.83,10.63,7.83,3.82,0,7-.91,8.6-2.54s2.14-3.71,2.19-9.26Z"
          transform="translate(-2.22 -2.02)"
        />
        <path
          style={{ fill: '#004b90' }}
          d="M145.7,74v2.91c0,3.36-.7,4.62-2.54,4.62-1.52,0-2.09-.91-2.09-3.4,0-3.32.72-4.13,3.57-4.13Zm-4.06-5.44c.05-3,.35-3.56,2-3.56s2.08.71,2.08,3.56v1.93c-1.06,0-1.58,0-2.13,0-6.57,0-8.86,2.09-8.86,8.19,0,4.69,1.74,6.82,5.35,6.82,2.29,0,3.87-.76,5.64-2.9l.1,2.6h6.52a29.45,29.45,0,0,1-.26-3.56V70.44c0-4.33-.25-5.54-1.42-6.92s-3.62-2.13-6.82-2.13a12.52,12.52,0,0,0-6.26,1.37c-1.78,1-2.24,2.19-2.28,5.75Z"
          transform="translate(-2.22 -2.02)"
        />
        <rect
          style={{ fill: '#004b90' }}
          x="154.12"
          y="49.2"
          width="6.46"
          height="33.88"
        />
        <path
          style={{ fill: '#004b90' }}
          d="M178.28,67.13v5.3h3.66v7.73a19.63,19.63,0,0,1-3.3.25c-2.34,0-3.47-.61-4-2.19-.45-1.21-.56-3.15-.56-9.87,0-1.68,0-3,.11-4.27l.1-2.75c.15-4.22,1.17-5.64,4.07-5.64,2.49,0,3.56,1.22,3.56,4.12l.05,1.72h7a18.26,18.26,0,0,0-.46-5.18c-.81-3.71-4.37-5.65-10.48-5.65a19.5,19.5,0,0,0-4.73.55,8.72,8.72,0,0,0-5.9,7.19,101.52,101.52,0,0,0-.57,11.08c0,4.89.11,7.23.57,9.37.81,4.43,4.53,6.72,10.73,6.72A42.57,42.57,0,0,0,189,84.13v-17Z"
          transform="translate(-2.22 -2.02)"
        />
        <path
          style={{ fill: '#004b90' }}
          d="M203.89,74v2.9c0,3.36-.72,4.63-2.55,4.63-1.53,0-2.08-.91-2.08-3.4,0-3.32.7-4.13,3.55-4.13Zm-4.08-5.44c.06-3,.37-3.56,2-3.56s2.08.71,2.08,3.56v1.92c-1.08,0-1.58,0-2.14,0-6.57,0-8.86,2.09-8.86,8.19,0,4.69,1.74,6.82,5.35,6.82,2.29,0,3.86-.77,5.65-2.9l.1,2.6h6.51a30.92,30.92,0,0,1-.25-3.57V70.43c0-4.32-.26-5.53-1.43-6.91s-3.62-2.14-6.81-2.14a12.66,12.66,0,0,0-6.27,1.38c-1.77,1-2.24,2.19-2.29,5.75Z"
          transform="translate(-2.22 -2.02)"
        />
        <path
          style={{ fill: '#004b90' }}
          d="M230.55,68.61c0-2.69-.3-3.77-1.42-5-1.32-1.48-3.45-2.2-6.61-2.2-5.45,0-8.55,2.4-8.55,6.57,0,2.69,1.32,4.42,4.69,6.1l1.72.82c3.56,1.78,4.28,2.5,4.28,4.23a2.27,2.27,0,0,1-2.29,2.49c-1.58,0-2.14-.88-2.19-3.11a9.15,9.15,0,0,0-.05-1.07h-6.32V78c0,5.09,2.81,7.37,8.86,7.37,5.23,0,8.45-2.64,8.45-7.07a6.21,6.21,0,0,0-1.73-4.43,18.37,18.37,0,0,0-4.94-3.15L223,70c-2-1-2.75-1.83-2.75-3a1.8,1.8,0,0,1,2-2c1.37,0,2,.76,2,2.59,0,.25.05.6.1,1.07Z"
          transform="translate(-2.22 -2.02)"
        />
        <path
          style={{ fill: '#004b90' }}
          d="M235.59,80.88h-1.22v3.58h-.63V80.88h-1.22v-.62h3.07Z"
          transform="translate(-2.22 -2.02)"
        />
        <path
          style={{ fill: '#004b90' }}
          d="M239.94,84.46h-.62V82.24c0-.25,0-.62,0-.74,0,.12-.17.42-.27.66l-1,2.37-1-2.37c-.11-.24-.23-.54-.27-.66,0,.12,0,.49,0,.74v2.22h-.6v-4.2h.65l.94,2.24c.1.24.24.55.29.72.06-.17.18-.47.28-.72l.93-2.24h.67Z"
          transform="translate(-2.22 -2.02)"
        />
      </svg>
    );
    switch (logoName) {
      case 'sempra energy':
        return sempraLogo;

      case 'sdge':
        return sdgeLogo;

      case 'socal':
        return socalLogo;

      case 'sempra external':
        return sempraExtrenalLogo;

      case 'sdge external':
        return sdgeExternal;

      case 'socal external':
        return socalExternal;

      default:
        return sempraLogo;
    }
  };
  return <div style={{ lineHeight: 0 }}>{getLogo()}</div>;
};

export default ATMBrandLogo;
