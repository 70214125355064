import React, { useState, useCallback, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import classNames from 'classnames';
import {
  ATMMenu,
  IATMMenuItemProps,
  IATMMenuProps,
} from '../../atoms/ATMMenu/ATMMenu.component';
import { ATMIcon } from '../../atoms/ATMIcon/ATMIcon.component';
import { ATMDropdown } from '../../atoms/ATMDropdown/ATMDropdown.component';
import styles from './MOLNavigation.module.scss';

export interface IMOLNavigationMenuProps extends IATMMenuItemProps {
  to?: string;
  target?: string;
  submenu?: this[];
}

type IProps = IATMMenuProps & {
  menus: IMOLNavigationMenuProps[];
  onClick?: (data: IMOLNavigationMenuProps) => void;
};

const MOLNavigation: React.FC<IProps> = ({ menus, onClick, ...props }) => {
  const urlLocation = useLocation();
  const [active, setActive] = useState<string[]>([]);
  const [width, setWidth] = React.useState(window.innerWidth);

  const getActive = (path: string) => {
    let list = path
      .replace(/^\/+/, '')
      .split('/')
      .filter((value) => value);

    if (list.length) {
      const selected: string[] = [];

      list.forEach((route, key) => {
        if (selected[key - 1]) {
          selected.push(`${selected[key - 1]}/${route}`);
        } else {
          selected.push(`/${route}`);
        }
      });

      list = selected;
    }

    return list;
  };

  const updateWidth = () => {
    setWidth(window.innerWidth);
  };

  const handleItemClick = useCallback(
    (route: any) => {
      if (route.to) {
        setActive(getActive(route.to));
      }

      if (onClick) {
        onClick(route);
      }
    },
    [setActive, onClick]
  );

  useEffect(() => {
    if (urlLocation) {
      const list = getActive(urlLocation.pathname);

      setActive(list.length ? list : ['/']);
    }

    window.addEventListener('resize', updateWidth);
    return () => window.removeEventListener('resize', updateWidth);
  }, [urlLocation, setActive]);

  const getMenuNum = () => {
    if (props.menuCount !== undefined) {
      return props.menuCount;
    }

    switch (true) {
      case width > 1700 && width <= 2000:
        if (menus.length > 8) {
          return 8;
        }

        return menus.length;

      case width > 1400 && width <= 1600:
        if (menus.length > 7) {
          return 7;
        }
        return menus.length;

      case width > 1000 && width <= 1400:
        if (menus.length > 6) {
          return 6;
        }
        return menus.length;

      case width > 900 && width <= 1000:
        if (menus.length > 5) {
          return 5;
        }
        return menus.length;

      case width > 767 && width <= 900:
        if (menus.length > 6) {
          return 4;
        }
        return menus.length;

      default:
        return menus.length;
    }
  };

  const getProps = useCallback(
    (value) => {
      // Checks if the `to` prop is linking to external site
      if (value.to && /(http(s?)):\/\//i.test(value.to)) {
        // eslint-disable-next-line no-param-reassign
        value.as = 'a';
        // eslint-disable-next-line no-param-reassign
        value.href = value.to;
        // eslint-disable-next-line no-param-reassign
        value.target = '_blank';
      } else if (value.to && urlLocation) {
        // eslint-disable-next-line no-param-reassign
        value.as = Link;
      }

      if (value.name && !value.text) {
        // eslint-disable-next-line no-param-reassign
        value.text = value.name;
      }

      if (value.icon && value.name && !value.content) {
        // eslint-disable-next-line no-param-reassign
        value.content = (
          <>
            {value.icon && <ATMIcon icon={value.icon} />}
            {value.name}
          </>
        );
      }

      return value;
    },
    [urlLocation]
  );
  const menuNum = getMenuNum();

  return (
    <ATMMenu
      vertical={props.vertical}
      pointing={props.pointing}
      secondary={props.secondary}
      size={props.size}
    >
      {menus.slice(0, menuNum).map(({ submenu, ...route }, key) => {
        const params: IATMMenuItemProps = {
          ...route,
          active: active.includes(route.to ?? ''),
        };

        if (submenu) {
          let parentKey = route.name?.toLowerCase();
          if (submenu.length && submenu[0].to) {
            parentKey = `/${submenu[0].to.replace(/^\/+/, '').split('/')[0]}`;
          }

          return (
            <ATMDropdown
              key={`nav_item_${key}`}
              {...getProps(route)}
              item
              className={classNames({
                active: active.includes(parentKey ?? ''),
              })}
            >
              <ATMDropdown.Menu>
                {submenu.map((value, k) => {
                  const values: IATMMenuItemProps = {
                    ...value,
                    active: active.includes(value.to ?? ''),
                  };

                  return (
                    <ATMDropdown.Item
                      key={`nav_sub_item_${k}`}
                      {...getProps(values)}
                      onClick={() => handleItemClick(values)}
                    />
                  );
                })}
              </ATMDropdown.Menu>
            </ATMDropdown>
          );
        }

        return (
          <ATMMenu.Item
            key={`nav_item_${key}`}
            {...getProps(params)}
            onClick={() => handleItemClick(route)}
          />
        );
      })}

      {menus.length > menuNum && width > 767 && (
        <ATMDropdown icon="ellipsis horizontal" className={styles.menuList}>
          <ATMDropdown.Menu>
            {menus.slice(menuNum).map(({ ...route }, k) => {
              const params: IATMMenuItemProps = {
                ...route,
                active: active.includes(route.to ?? ''),
              };

              return (
                <ATMDropdown.Item
                  key={`nav_item_${k}`}
                  {...getProps(params)}
                  onClick={() => handleItemClick(route)}
                />
              );
            })}
          </ATMDropdown.Menu>
        </ATMDropdown>
      )}
    </ATMMenu>
  );
};

export { MOLNavigation };
