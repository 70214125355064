import React, { useState, useCallback } from 'react';
import classNames from 'classnames';
import { Visibility, Sidebar } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import {
  MOLNavigation,
  IMOLNavigationMenuProps,
} from '../../molecules/MOLNavigation/MOLNavigation.component';
import { ATMIcon } from '../../atoms/ATMIcon/ATMIcon.component';
import styles from './ORGHeader.module.scss';
import {
  ATMUserMenu,
  IATMUserMenuProps,
} from '../../atoms/ATMUserMenu/ATMUserMenu.component';
import { MOLAppDropdown } from '../../molecules/MOLAppDropdown/MOLAppDropdown.component';
import { ATMLabel } from '../../atoms/ATMLabel/ATMLabel.component';
import {
  ATMSearch,
  IATMSearchProps,
} from '../../atoms/ATMSearch/ATMSearch.component';
import { ATMBrandLogo } from '../../atoms/ATMBrandLogo/ATMBrandLogo.component';
import { IATMMenuItemProps } from '../../atoms/ATMMenu/ATMMenu.component';
import { ATMResponsive } from '../../atoms/ATMResponsive/ATMResponsive.component';

export type IORGHeaderProps = {
  homeLink?: string;
  environment?: string | boolean;
  utility?: React.ReactNode;
  application?: boolean | string;
  menus: IMOLNavigationMenuProps[];
  search?: IATMSearchProps;
  showUser?: boolean;
  user?: IATMUserMenuProps['user'];
  onNavigationClick?: (data: IATMMenuItemProps) => void;
  onLogout?: () => void;
  siteLink?: boolean;
  sitePath?: string;
  siteName?: string;
  showHelp?: boolean;
  logoName?: string;
  helpLinkUrl?: string;
  additionalMenu?: React.ReactNode;
  menuCount?: number;
};

const DesktopContainer: React.FC<IORGHeaderProps> = ({
  homeLink = '/',
  environment = false,
  menus,
  application = false,
  utility,
  search,
  children,
  showUser = true,
  user,
  onNavigationClick,
  onLogout,
  siteLink = false,
  sitePath = '#',
  siteName = 'View App site',
  showHelp = false,
  logoName = 'Sempra Energy',
  helpLinkUrl = '/userguide',
  menuCount,
  additionalMenu,
}) => {
  const [fixed, setFixed] = useState(false);
  return (
    <div>
      <Visibility
        once={false}
        onBottomPassed={() => setFixed(true)}
        onBottomPassedReverse={() => setFixed(false)}
      >
        <div className={styles.wrapper}>
          <div className={styles.topHeader}>
            <div>
              <ul className={styles.branding}>
                <li
                  className={
                    logoName.toString().toLowerCase() === 'socal'
                      ? styles.logoSocal
                      : styles.logoOther
                  }
                >
                  <Link to={homeLink} className={styles.logo}>
                    <ATMBrandLogo
                      height={30}
                      logoName={logoName}
                      color="#ffffff"
                    />
                    <h1>Sempra Energy</h1>
                  </Link>
                </li>
                {utility && <li>{utility}</li>}

                {environment !== false &&
                  !environment.toString().toLowerCase().includes('prod') && (
                    <li
                      className={
                        logoName.toString().toLowerCase() === 'socal'
                          ? styles.logoSocal
                          : styles.logoOther
                      }
                    >
                      <ATMLabel
                        className={
                          environment.toString().toLowerCase().includes('dev')
                            ? styles.environmentDev
                            : styles.environmentTestProd
                        }
                        horizontal
                      >
                        {`${environment || 'Dev'} Environment`}
                      </ATMLabel>
                    </li>
                  )}
              </ul>
            </div>
            {search && (
              <div>
                <ATMSearch
                  size="mini"
                  {...search}
                  className={classNames(styles.searchBar, search.className)}
                />
              </div>
            )}
            <div className={styles.displayFlex}>
              {showHelp && (
                <div className={styles.helpLink}>
                  <ATMIcon
                    className={styles.helpIcon}
                    onClick={() => {
                      window.open(`${helpLinkUrl}`, '_blank');
                    }}
                    circular
                    name="help"
                  />
                </div>
              )}
              {showUser && (
                <div>
                  <ATMUserMenu
                    user={user}
                    onLogout={onLogout}
                    helpLinkUrl={helpLinkUrl}
                  />
                </div>
              )}
            </div>
          </div>
          <div
            id="navigation"
            className={
              styles.navigation + (fixed ? ` ${styles.fixedNavigation}` : '')
            }
          >
            <div>
              {application && (
                <div className={styles.applicationContainer}>
                  <MOLAppDropdown application={application} />
                </div>
              )}
              <div>
                <MOLNavigation
                  menus={menus}
                  pointing
                  secondary
                  menuCount={menuCount}
                  onClick={onNavigationClick}
                />
              </div>
            </div>
            {siteLink && (
              <a
                className={styles.siteTag}
                href={sitePath}
                target="_blank"
                rel="noopener noreferrer"
              >
                {siteName}
                <span>
                  <ATMIcon
                    className={styles.siteTag}
                    name="external alternate"
                  />
                </span>
              </a>
            )}
            {additionalMenu && <div> {additionalMenu} </div>}
          </div>
        </div>
      </Visibility>
      {children}
    </div>
  );
};

const MobileContainer: React.FC<IORGHeaderProps> = ({
  homeLink = '/',
  menus,
  application = false,
  search,
  children,
  showUser = true,
  user,
  onNavigationClick,
  onLogout,
}) => {
  const [sidebarOpened, setSidebarOpened] = useState(false);
  const [searchOpened, setSearchOpened] = useState(false);

  const handleIcon = useCallback(() => {
    if (searchOpened) {
      setSearchOpened(false);
    } else {
      setSidebarOpened(true);
    }
  }, [searchOpened, setSearchOpened, setSidebarOpened]);

  return (
    <Sidebar.Pushable>
      <Sidebar
        animation="push"
        inverted
        onHide={() => setSidebarOpened(false)}
        vertical
        visible={sidebarOpened}
        className={styles.sidebar}
      >
        {application && <MOLAppDropdown application={application} isMobile />}
        <MOLNavigation menus={menus} vertical onClick={onNavigationClick} />
        {showUser && <ATMUserMenu user={user} isMobile onLogout={onLogout} />}
      </Sidebar>

      <Sidebar.Pusher dimmed={sidebarOpened} className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.topHeaderMobile}>
            <div className={styles.sidebarTrigger}>
              <ATMIcon
                name={sidebarOpened || searchOpened ? 'arrow left' : 'sidebar'}
                onClick={handleIcon}
              />
            </div>
            {!searchOpened && (
              <Link to={homeLink} className={styles.logo}>
                <ATMBrandLogo height={25} color="#ffffff" />
                <h1>Sempra Energy</h1>
              </Link>
            )}
            {search && (
              <div
                className={classNames({
                  [styles.search]: true,
                  [styles.open]: searchOpened,
                })}
              >
                {searchOpened ? (
                  <ATMSearch
                    size="mini"
                    {...search}
                    className={classNames(styles.searchBar, search.className)}
                  />
                ) : (
                  <ATMIcon
                    name="search"
                    onClick={() => setSearchOpened(true)}
                  />
                )}
              </div>
            )}
          </div>
        </div>

        {children}
      </Sidebar.Pusher>
    </Sidebar.Pushable>
  );
};

const ORGHeader: React.FC<IORGHeaderProps> = ({ children, ...props }) => {
  return (
    <div>
      <ATMResponsive greaterThan="mobile">
        <DesktopContainer {...props}>{children}</DesktopContainer>
      </ATMResponsive>
      <ATMResponsive media="mobile">
        <MobileContainer {...props}>{children}</MobileContainer>
      </ATMResponsive>
    </div>
  );
};

export { ORGHeader };
