import React, { memo, useState } from 'react';
import { List } from 'semantic-ui-react';
import { ATMPopover } from '../../atoms/ATMPopover/ATMPopover.component';
import { ATMHeader } from '../../atoms/ATMHeader/ATMHeader.component';
import styles from './MOLTitleHeader.module.scss';

export type MOLUserInfoProps = {
  infoHeader?: string;
  title: string;
  subTitle?: string;
  icon?: React.ReactNode;
  hoverIcon?: React.ReactNode;
  colors?: Array<string>;
  basic?: boolean;
};
export const MOLTitleHeader: React.FC<MOLUserInfoProps> = ({
  infoHeader,
  title,
  subTitle,
  icon,
  hoverIcon,
  colors = ['#DB9425', '#126398', '#833333', '#256F18', '#674589'],
  basic,
}) => {
  const [addCheckBox, setAddCheckBox] = useState(false);
  const fullName = title?.split(' ');
  let initials = '';
  if (fullName?.length === 1) {
    initials = fullName.shift()?.charAt(0).toUpperCase() || '';
  } else {
    const firstChar = fullName.shift()?.charAt(0).toUpperCase();
    const lastChar = fullName.pop()?.charAt(0).toUpperCase();
    let fullChar = '';
    if (firstChar && lastChar) {
      fullChar = firstChar + lastChar;
    }
    initials = fullChar;
  }
  const handleCheckBox = (flag: boolean) => {
    setAddCheckBox(flag);
  };

  if (basic) {
    return (
      <>
        <ATMHeader as="h3" content={infoHeader} />
        <div className={styles.displayFlex}>
          <span
            style={{
              backgroundColor:
                colors[Math.floor(Math.random() * colors.length)],
            }}
            className={styles.initials}
          >
            {icon || initials}
          </span>

          <List.Item className={styles.pushCorners}>
            <List.Content>
              <ATMPopover
                inverted
                size="mini"
                content={title}
                trigger={
                  <List.Header className={styles.title}>{title}</List.Header>
                }
              />
              <List.Description className={styles.subTitle}>
                {subTitle}
              </List.Description>
            </List.Content>
          </List.Item>
        </div>
      </>
    );
  }

  return (
    <>
      <ATMHeader as="h3" content={infoHeader} />
      <div className={styles.displayFlex}>
        {addCheckBox ? (
          <span
            style={{
              backgroundColor: '#ffffff',
            }}
            className={styles.initials}
            onMouseLeave={() => handleCheckBox(false)}
          >
            {hoverIcon}
          </span>
        ) : (
          <span
            style={{
              backgroundColor:
                colors[Math.floor(Math.random() * colors.length)],
            }}
            className={styles.initials}
            onMouseOver={() => handleCheckBox(true)}
            onFocus={() => handleCheckBox(true)}
          >
            {icon || initials}
          </span>
        )}

        <List.Item className={styles.pushCorners}>
          <List.Content>
            <ATMPopover
              inverted
              size="mini"
              content={title}
              trigger={
                <List.Header className={styles.title}>{title}</List.Header>
              }
            />
            <List.Description className={styles.subTitle}>
              {subTitle}
            </List.Description>
          </List.Content>
        </List.Item>
      </div>
    </>
  );
};
export default memo(MOLTitleHeader);
