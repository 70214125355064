import React, { useMemo } from 'react';
import { ATMImage } from '../ATMImage/ATMImage.component';
import {
  ATMDropdown,
  IATMDropdownItemProps,
} from '../ATMDropdown/ATMDropdown.component';
import { ATMMenu } from '../ATMMenu/ATMMenu.component';
import { ATMIcon } from '../ATMIcon/ATMIcon.component';
import styles from './ATMUserMenu.module.scss';

type IATMUser = {
  first_name?: string;
  last_name?: string;
  name?: string;
  username?: string;
  avatar?: string;
};

export type IATMUserMenuProps = {
  user?: IATMUser;
  isMobile?: boolean;
  onLogout?: () => void;
  helpLinkUrl?: string;
};

const ATMUserMenu: React.FC<IATMUserMenuProps> = ({
  user,
  isMobile = false,
  onLogout,
  helpLinkUrl = '/userguide',
}) => {
  const trigger = useMemo(() => {
    let content = 'Welcome';

    if (user) {
      if (user.username) {
        content = user.username;
      } else if (user.name) {
        content = user.name;
      } else if (user.first_name && user.last_name) {
        content = `${user.first_name} ${user.last_name}`;
      }
    }

    return (
      <div className={styles.header}>
        <span
          className={user && Object.keys(user).length ? styles.fadeIn : ' '}
        >
          {content}
        </span>
        {user?.avatar ? (
          <ATMImage src={user?.avatar} avatar />
        ) : (
          <div className={styles.avatar}>
            <ATMIcon name="user" size="small" />
          </div>
        )}
      </div>
    );
  }, [user]);

  const options: IATMDropdownItemProps[] = [
    {
      key: 'account',
      text: 'Account Settings',
      icon: 'cog',
      onClick: () => {
        window.open('https://myaccount.microsoft.com/', '_blank');
      },
    },
    {
      key: 'help',
      text: 'Help',
      icon: 'help',
      onClick: () => {
        window.open(`${helpLinkUrl}`, '_blank');
      },
    },

    {
      key: 'sign-out',
      text: 'Logout',
      icon: 'sign out',
      onClick: () => {
        if (onLogout) {
          onLogout();
        }
      },
    },
  ];

  if (isMobile) {
    return (
      <ATMMenu vertical>
        <ATMMenu.Item key="trigger">{trigger}</ATMMenu.Item>
        {options.map((value) => (
          <ATMMenu.Item key={value.key} onClick={() => value.onClick}>
            <ATMIcon name={value.icon as any} />
            {value.text}
          </ATMMenu.Item>
        ))}
      </ATMMenu>
    );
  }

  return (
    <ATMDropdown
      trigger={trigger}
      options={options}
      pointing="top right"
      icon={null}
    />
  );
};

export { ATMUserMenu };
