import React from 'react';
import { ATMGrid } from '../../atoms/ATMGrid/ATMGrid.component';
import { ATMHeader } from '../../atoms/ATMHeader/ATMHeader.component';
import { ATMPopover } from '../../atoms/ATMPopover/ATMPopover.component';
import { ATMSegment } from '../../atoms/ATMSegment/ATMSegment.component';
import styles from './MOLInfoTable.module.scss';

export interface InformationTypeProps {
  header?: string;
  content: InformationtableContent[];
  handleClick?: () => void;
  contentClassName?: string;
  truncate?: boolean;
}
export interface InformationtableContent {
  label: string;
  value: string | React.ReactNode | null | undefined;
  tooltipContent?: string;
}
const MOLInfoTable: React.FC<InformationTypeProps> = (props) => {
  const { header, content, contentClassName } = props;

  return (
    <>
      <ATMHeader as="h3" content={header} />
      <ATMSegment className={styles.informationTableSegment}>
        <ATMGrid celled className={styles.informationTableGrid}>
          {content.map((row, i) => {
            return (
              <ATMGrid.Row key={`${row.label}_${i}`} columns="2">
                <ATMGrid.Column
                  className={`${styles.infoTablePadding} ${styles.informationTableLeftColumn}`}
                >
                  {row.tooltipContent ? (
                    <ATMPopover
                      trigger={
                        <div className={styles.informationTableLabel}>
                          {row.label}
                        </div>
                      }
                      content={row.tooltipContent}
                    />
                  ) : (
                    <div className={styles.informationTableLabel}>
                      {row.label}
                    </div>
                  )}
                </ATMGrid.Column>
                <ATMGrid.Column
                  className={
                    contentClassName
                      ? `${styles.infoTablePadding} ${styles.noPadding}`
                      : `${styles.infoTablePadding}`
                  }
                >
                  {row.tooltipContent && row.value ? (
                    <ATMPopover
                      className={styles.delay}
                      trigger={
                        <div className={props.truncate ? styles.truncate : ''}>
                          {row.value}
                        </div>
                      }
                      content={row.tooltipContent}
                    />
                  ) : (
                    <div>
                      {row.value ? (
                        <div className={props.truncate ? styles.truncate : ''}>
                          {row.value}
                        </div>
                      ) : (
                        <p style={{ color: 'rgba(0,0,0,50%)' }}>&#8210;</p>
                      )}
                    </div>
                  )}
                </ATMGrid.Column>
              </ATMGrid.Row>
            );
          })}
        </ATMGrid>
      </ATMSegment>
    </>
  );
};

export { MOLInfoTable };
