import React, { useCallback, useEffect, useState } from 'react';
import { Input, InputProps } from 'semantic-ui-react';
import { ATMIcon } from '../ATMIcon/ATMIcon.component';
import './ATMInput.module.scss';

export type IATMInputProps = InputProps & {
  isPhone?: boolean;
  isCurrency?: boolean;
  thousandSeparator?: boolean;
  isZip?: boolean;
  clearable?: boolean;
};

export const REGEX_PHONE =
  /^$|(\(\d{3}\)\s\d{3}\s-\s\d{4}|\+1\s\(\d{3}\)\s\d{3}\s-\s\d{4})$/;
export const REGEX_ZIP = /(^\d{5}$)|(^\d{5}-\d{4}$)/;

export const formatPhoneNumber: any = (value = '', originalValue = '') => {
  let formattedValue = value;

  if (formattedValue.length > originalValue.length) {
    formattedValue = (value ?? '').replace(/[^\d]/g, '').trim();

    if (/^[A-Za-z]+$/.exec(formattedValue) || !formattedValue.length) {
      return '';
    }

    if (formattedValue[0] === '1') {
      formattedValue = `+1 (${formattedValue.slice(
        1,
        4
      )}) ${formattedValue.slice(4, 7)} - ${formattedValue.slice(7, 11)}`;
    } else {
      formattedValue = `(${formattedValue.slice(0, 3)}) ${formattedValue.slice(
        3,
        6
      )} - ${formattedValue.slice(6, 10)}`;
    }
  }

  return formattedValue;
};

export const currencyRegex: any = (value: any) => {
  let newValue = value
    ?.toString()
    .replace(/(?!\.)\D/g, '')
    .replace(/(?:\..|$)\./g, '')
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  if (newValue !== undefined && newValue.includes('.')) {
    newValue = newValue.split('.');
    newValue = `${newValue[0]}.${newValue[1].substring(0, 2)}`;
  }
  return newValue;
};

export const formatCurrency: any = (value: string | number) => {
  let currencyValue = value;
  currencyValue = currencyRegex(currencyValue);

  if (typeof value === 'string' && value?.indexOf('-') > -1) {
    currencyValue = `-${currencyValue}`;
  }

  if (typeof value === 'number') {
    currencyValue = value.toString();
    if (typeof currencyValue === 'string' && currencyValue?.indexOf('-') > -1) {
      currencyValue = currencyRegex(currencyValue);
      currencyValue = `-${currencyValue}`;
    } else {
      currencyValue = currencyRegex(currencyValue);
    }
  }

  return currencyValue;
};

export const formatZipcode: any = (value = '', originalValue = '') => {
  let formattedValue = value;

  if (formattedValue.length > originalValue.length) {
    formattedValue = (value ?? '').replace(/[^\d]/g, '').trim();

    if (/^[A-Za-z]+$/.exec(formattedValue) || !formattedValue.length) {
      return '';
    }

    if (formattedValue.length < 6) {
      return formattedValue;
    }

    formattedValue = `${formattedValue.slice(0, 5)}-${formattedValue.slice(
      5,
      9
    )}`;
  }

  return formattedValue;
};

export const ATMInput: React.FC<IATMInputProps> = ({
  isPhone,
  isCurrency,
  thousandSeparator,
  isZip,
  clearable,
  ...props
}) => {
  const { onChange, defaultValue, value: originalValue } = props;
  const [value, setValue] = useState<string>(defaultValue || originalValue);
  const [clearNode, setClearNode] = useState<React.ReactNode>(<></>);

  const isCustom = isPhone || isCurrency;

  const handleClear = useCallback(() => {
    setValue('');
  }, [setValue]);

  useEffect(() => {
    if (
      clearable &&
      ((props.value && props.value.length) ||
        (props.defaultValue && props.defaultValue.length))
    ) {
      setClearNode(<ATMIcon name="delete" link onClick={handleClear} />);
    }

    if (props.value && props.value.length) {
      setValue(props.value);
    }

    if (props.defaultValue && props.defaultValue.length) {
      setValue(props.defaultValue);
    }

    if (isCustom) {
      setValue(defaultValue);
    }
  }, [
    clearable,
    props.value,
    props.defaultValue,
    setValue,
    setClearNode,
    defaultValue,
    isCustom,
    setValue,
  ]);

  useEffect(() => {
    if (isCustom) {
      setValue(originalValue);
    }
  }, [originalValue, isCustom, setValue]);

  const handleInputChange = useCallback(
    (event, data) => {
      event.persist();
      if (clearable && data.value && data.value.length) {
        setClearNode(<ATMIcon name="delete" link onClick={handleClear} />);
      }
      const dataValue = event.currentTarget.value;

      setValue(() => {
        // This will handle onChange from react hook form
        if (onChange) {
          onChange(event, {
            ...data,
            value: dataValue,
          });
        }

        return dataValue;
      });

      return dataValue;
    },
    [setClearNode, setValue, onChange]
  );

  const handleChange = useCallback(
    (event, data) => {
      event.persist();
      let result = event.currentTarget.value;

      if (clearable && result && result.length) {
        setClearNode(<ATMIcon name="delete" link onClick={handleClear} />);
      }

      setValue((prevState) => {
        result = formatPhoneNumber(result ?? '', prevState ?? '');

        // This will handle onChange from react hook form
        if (onChange) {
          onChange(event, {
            ...data,
            error: !REGEX_PHONE.test(result),
            value: result,
          });
        }

        return result;
      });

      return result;
    },
    [onChange, setValue]
  );

  const handleCurrency = useCallback(
    (event, data) => {
      event.persist();
      const result = event.currentTarget.value;

      if (clearable && result && result.length) {
        setClearNode(<ATMIcon name="delete" link onClick={handleClear} />);
      }

      setValue(() => {
        // This will handle onChange from react hook form
        if (onChange) {
          onChange(event, {
            ...data,
            value: result,
          });
        }

        return result;
      });

      return result;
    },
    [onChange, setValue]
  );

  const handleOnBlurCurrency = useCallback(
    (event, data) => {
      event.persist();
      let result = event.currentTarget.value;
      const resultArray = result.split('.');

      if (clearable && result && result.length) {
        setClearNode(<ATMIcon name="delete" link onClick={handleClear} />);
      }

      if (!(resultArray.length > 1) && resultArray[0] !== '') {
        result += '.00';
      }

      setValue(() => {
        // This will handle onBlur from react hook form
        if (props.onBlur) {
          props.onBlur(event, {
            ...data,
            value: result,
          });
        }

        return result;
      });

      return result;
    },
    [props.onBlur, setValue]
  );

  const handleZipChange = useCallback(
    (event, data) => {
      event.persist();
      let result = event.currentTarget.value;

      if (clearable && result && result.length) {
        setClearNode(<ATMIcon name="delete" link onClick={handleClear} />);
      }

      setValue((prevState) => {
        result = formatZipcode(result ?? '', prevState ?? '');

        // This will handle onChange from react hook form
        if (onChange) {
          onChange(event, {
            ...data,
            error: !REGEX_ZIP.test(result),
            value: result,
          });
        }

        return result;
      });

      return result;
    },
    [onChange, setValue]
  );

  if (isPhone && clearable) {
    return (
      <Input
        size={props.size ? props.size : 'small'}
        icon={value && value.length ? clearNode : <></>}
        {...{
          ...props,
          onChange: handleChange,
          onPaste: handleChange,
          value,
        }}
      />
    );
  }

  if (isPhone && !clearable) {
    return (
      <Input
        size={props.size ? props.size : 'small'}
        {...{
          ...props,
          onChange: handleChange,
          onPaste: handleChange,
          value,
        }}
      />
    );
  }

  if (isCurrency && clearable) {
    return (
      <Input
        size={props.size ? props.size : 'small'}
        icon={value && value.length ? clearNode : <></>}
        {...{
          ...props,
          label: 'US $',
          labelPosition: 'left',
          onChange: handleCurrency,
          onPaste: handleCurrency,
          onBlur: handleOnBlurCurrency,
          value:
            thousandSeparator || thousandSeparator === undefined
              ? formatCurrency(value)
              : value,
        }}
      />
    );
  }

  if (isCurrency && !clearable) {
    return (
      <Input
        size={props.size ? props.size : 'small'}
        {...{
          ...props,
          label: 'US $',
          labelPosition: 'left',
          onChange: handleCurrency,
          onPaste: handleCurrency,
          onBlur: handleOnBlurCurrency,
          value:
            thousandSeparator || thousandSeparator === undefined
              ? formatCurrency(value)
              : value,
        }}
      />
    );
  }

  if (isZip && clearable) {
    return (
      <Input
        size={props.size ? props.size : 'small'}
        icon={value && value.length ? clearNode : <></>}
        {...{
          ...props,
          onChange: handleZipChange,
          onPaste: handleZipChange,
          value,
        }}
      />
    );
  }

  if (isZip && !clearable) {
    return (
      <Input
        size={props.size ? props.size : 'small'}
        {...{
          ...props,
          onChange: handleZipChange,
          onPaste: handleZipChange,
          value,
        }}
      />
    );
  }

  if (clearable) {
    return (
      <Input
        size={props.size ? props.size : 'small'}
        icon={value && value.length ? clearNode : <></>}
        {...{
          ...props,
          onChange: handleInputChange,
          onPaste: handleInputChange,
          value,
        }}
      />
    );
  }

  return <Input size={props.size ? props.size : 'small'} {...props} />;
};
