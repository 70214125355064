import React, { useState } from 'react';
import {
  Menu,
  MenuProps,
  MenuItemProps,
  MenuHeaderProps,
  MenuMenuProps,
} from 'semantic-ui-react';
import classNames from 'classnames';
import styles from './ATMMenu.module.scss';
import { ATMButton } from '../ATMButton/ATMButton.component';
import { ATMIcon } from '../ATMIcon/ATMIcon.component';

export type IATMMenuProps = MenuProps & {
  collapsible?: boolean;
  truncate?: boolean;
};
export interface IATMMenu extends React.FC<IATMMenuProps> {
  Header: typeof Menu.Header;
  Item: typeof Menu.Item;
  Menu: typeof Menu.Menu;
}

const ATMMenu: IATMMenu = ({ children, ...props }) => {
  const [nav, setNav] = useState(false);

  if (props.collapsible) {
    return (
      <div className={styles.collapsibleMenu}>
        <Menu
          className={classNames(
            props.className,
            nav ? styles.collapseNav : '',
            { [styles.collapseMenu]: props.collapsible }
          )}
          {...props}
        >
          <ATMMenu.Item onClick={() => setNav(true)}>
            <div>
              <ATMIcon name="bars" />
              MENU
            </div>
          </ATMMenu.Item>
          {children}
        </Menu>
        {nav && (
          <ATMButton
            icon="bars"
            className={styles.barBtn}
            onClick={() => setNav(false)}
          />
        )}
      </div>
    );
  }

  return (
    <Menu
      className={classNames(
        props.className,
        props.truncate ? styles.truncate : ''
      )}
      {...props}
    >
      {children}
    </Menu>
  );
};

ATMMenu.Header = Menu.Header;
ATMMenu.Item = Menu.Item;
ATMMenu.Menu = Menu.Menu;

export type IATMMenuHeaderProps = MenuHeaderProps;
export type IATMMenuItemProps = MenuItemProps;
export type IATMMenuMenuProps = MenuMenuProps;

export { ATMMenu };
