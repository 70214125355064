import React, { forwardRef } from 'react';
import {
  Form,
  FormProps,
  FormButtonProps,
  FormFieldProps,
  FormInputProps,
  FormRadioProps,
  FormSelectProps,
  FormTextAreaProps,
  FormGroupProps,
  FormCheckboxProps,
  FormDropdownProps,
} from 'semantic-ui-react';
import {
  Controller,
  SubmitErrorHandler,
  UnpackNestedValue,
  useForm,
  UseFormMethods,
  UseFormOptions,
  FormProvider,
  useFormContext,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { InferType, Schema } from 'yup';

export type IATMFormOnSubmit<T> = (
  data: UnpackNestedValue<T>,
  formProps: UseFormMethods<T>,
  event?: React.BaseSyntheticEvent
) => any | Promise<any>;

type ISchema = Schema<any>;
/**
 * @reference https://react-hook-form.com/get-started/#Applyvalidation
 */
export type IATMFormProps<T extends ISchema, S = InferType<T>> = Omit<
  FormProps,
  'onSubmit'
> &
  UseFormOptions<S> & {
    validationSchema?: T;
    provider?: boolean;
    onSubmit?: IATMFormOnSubmit<S>;
    onError?: SubmitErrorHandler<S>;
    children: React.ReactNode | ((form: UseFormMethods<S>) => React.ReactNode);
  };

export type IATMFormController = Parameters<typeof Controller>[number];
export type IATMFormButton = FormButtonProps;
export type IATMFormFieldProps = FormFieldProps;
export type IATMFormInputProps = FormInputProps;
export type IATMFormRadioProps = FormRadioProps;
export type IATMFormSelectProps = FormSelectProps;
export type IATMFormTextAreaProps = FormTextAreaProps;
export type IATMFormGroupProps = FormGroupProps;
export type IATMFormCheckboxProps = FormCheckboxProps;
export type IATMFormDropdownProps = FormDropdownProps;

const SForm = <T extends ISchema>(
  {
    formRef,
    children,
    onSubmit,
    onError,
    // react-hook-form options
    validationSchema,
    mode,
    reValidateMode,
    defaultValues,
    resolver,
    context,
    shouldFocusError,
    shouldUnregister,
    criteriaMode,
    provider = false,
    ...props
  }: React.PropsWithChildren<IATMFormProps<T>>,
  ref
) => {
  const formProps = useForm({
    resolver: validationSchema ? yupResolver(validationSchema) : resolver,
    mode,
    reValidateMode,
    defaultValues,
    context,
    shouldFocusError,
    shouldUnregister,
    criteriaMode,
  });

  const form = (
    <Form
      ref={ref}
      size={props.size ? props.size : 'small'}
      {...props}
      onSubmit={
        onSubmit &&
        formProps.handleSubmit(
          (data, event) => onSubmit(data, formProps, event),
          onError
        )
      }
    >
      {children instanceof Function ? children(formProps) : children}
    </Form>
  );

  return provider ? <FormProvider {...formProps}>{form}</FormProvider> : form;
};

type IATMForm = (<T extends ISchema>(
  props: IATMFormProps<T> & { ref?: React.RefObject<HTMLFormElement> }
) => ReturnType<typeof SForm>) & {
  Controller: typeof Controller;
  Button: typeof Form.Button;
  Field: typeof Form.Field;
  Input: typeof Form.Input;
  Radio: typeof Form.Radio;
  Select: typeof Form.Select;
  TextArea: typeof Form.TextArea;
  Group: typeof Form.Group;
  Checkbox: typeof Form.Checkbox;
  Dropdown: typeof Form.Dropdown;
};

const ATMForm = forwardRef(SForm) as unknown as IATMForm;

ATMForm.Controller = Controller;
ATMForm.Button = Form.Button;
ATMForm.Field = Form.Field;
ATMForm.Input = Form.Input;
ATMForm.Radio = Form.Radio;
ATMForm.Select = Form.Select;
ATMForm.TextArea = Form.TextArea;
ATMForm.Group = Form.Group;
ATMForm.Checkbox = Form.Checkbox;
ATMForm.Dropdown = Form.Dropdown;

export const ATMFormProvider = FormProvider;
export const useATMFormContext = useFormContext;

export { ATMForm };
