import React from 'react';
import {
  Button,
  ButtonProps,
  ButtonOrProps,
  ButtonGroupProps,
} from 'semantic-ui-react';
import classNames from 'classnames';
import styles from './ATMButton.module.scss';

export type iconcolors = 'blue' | 'grey' | 'black';

export type IATMButtonMyProps = ButtonProps & {
  iconcolor?: iconcolors;
  borderless?: boolean;
};

export interface IATMButton extends React.FC<IATMButtonMyProps> {
  Group: typeof Button.Group;
  Content: typeof Button.Content;
  Or: typeof Button.Or;
}

const ATMButton: IATMButton = ({ children, ...props }) => {
  if (props.iconcolor || props.borderless) {
    return (
      <Button
        {...props}
        size={props.size ? props.size : 'small'}
        className={classNames(props.className, {
          [styles.blue]: props.iconcolor === 'blue',
          [styles.grey]: props.iconcolor === 'grey',
          [styles.black]: props.iconcolor === 'black',
          [styles.borderless]: props.borderless,
        })}
      >
        {children}
      </Button>
    );
  }
  return (
    <Button size={props.size ? props.size : 'small'} {...props}>
      {children}
    </Button>
  );
};

ATMButton.Group = Button.Group;
ATMButton.Content = Button.Content;
ATMButton.Or = Button.Or;

export type IATMButtonProps = ButtonProps;
export type IATMButtonGroupProps = ButtonGroupProps;
export type IATMButtonOrProps = ButtonOrProps;

export { ATMButton };
