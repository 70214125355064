import _ from 'lodash';
import moment from 'moment';
import React, { useCallback, useState } from 'react';
import {
  MonthInput,
  MonthRangeInput,
  MonthRangeInputProps,
  YearInput,
} from 'semantic-ui-calendar-react';
import classNames from 'classnames';
import { ATMLabel } from '../ATMLabel/ATMLabel.component';
import styles from './ATMMonthYearPicker.module.scss';
import { ATMDropdown } from '../ATMDropdown/ATMDropdown.component';

export type IATMMonthYearPickerProps = MonthRangeInputProps & {
  isDropdown?: boolean;
  startYear?: number;
  endYear?: number;
  isMonthPicker?: boolean;
  isYearPicker?: boolean;
  monthPlaceHolder?: string;
  yearPlaceHolder?: string;
  monthYearPlaceHolder?: string;
  isDisabled?: boolean;
  errorMessage?: string | undefined;
};
const monthOptions = [
  {
    key: 'January',
    text: 'January',
    value: 'January',
  },
  {
    key: 'February',
    text: 'February',
    value: 'February',
  },
  {
    key: 'March',
    text: 'March',
    value: 'March',
  },
  {
    key: 'April',
    text: 'April',
    value: 'April',
  },
  {
    key: 'May',
    text: 'May',
    value: 'May',
  },
  {
    key: 'June',
    text: 'June',
    value: 'June',
  },
  {
    key: 'July',
    text: 'July',
    value: 'July',
  },
  {
    key: 'August',
    text: 'August',
    value: 'August',
  },
  {
    key: 'September',
    text: 'September',
    value: 'September',
  },
  {
    key: 'October',
    text: 'October',
    value: 'October',
  },
  {
    key: 'November ',
    text: 'November',
    value: 'November',
  },
  {
    key: 'December',
    text: 'December',
    value: 'December',
  },
];

const getYears = (
  number: number,
  props: React.PropsWithChildren<IATMMonthYearPickerProps>
) =>
  _.times(number, (index) => ({
    key: index,
    text:
      props.startYear === undefined ? 1990 + index : props.startYear + index,
    value:
      props.startYear === undefined ? 1990 + index : props.startYear + index,
  }));

export const ATMMonthYearPicker: React.FC<IATMMonthYearPickerProps> = (
  props
) => {
  const { onChange, value, errorMessage } = props;
  const [data, setDataValue] = useState(value);
  const [month, setMonth] = useState(value ? moment(value).format('MMMM') : '');
  const [year, setYear] = useState(value ? moment(value).format('YYYY') : '');
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleChange = useCallback(
    (event, val) => {
      const result = val.value;
      setDataValue(() => {
        if (onChange) {
          onChange(event, {
            ...val,
            data: result,
          });
        }

        return result;
      });

      return result;
    },
    [onChange, setDataValue]
  );

  const handleYearChange = (event: any, val: any) => {
    setYear(val.value);
    if (year === '') {
      setOpen(true);
    }

    if (month && val.value) {
      onChange(event, {
        month,
        year: val.value,
      });
    }
  };

  const handleMonthChange = (event: any, val: any) => {
    setMonth(val.value);
    setOpen(false);
    if (year && val.value) {
      onChange(event, {
        month: val.value,
        year,
      });
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  if (props.isDropdown) {
    let yearRange = 51;
    if (props.endYear !== undefined && props.startYear !== undefined) {
      yearRange = props.endYear - props.startYear + 1;
    }

    return (
      <div className={styles.monthYear}>
        <ATMDropdown
          size="small"
          className={styles.yearPicker}
          placeholder={
            props.yearPlaceHolder === undefined ? 'Year' : props.yearPlaceHolder
          }
          search
          selection
          options={getYears(yearRange, props)}
          onChange={(event, val) => handleYearChange(event, val)}
          value={year ? parseInt(year, 10) : year}
          onClick={handleClose}
          disabled={props.isDisabled}
        />
        <ATMDropdown
          size="small"
          className={styles.monthPicker}
          placeholder={
            props.monthPlaceHolder === undefined
              ? 'Month'
              : props.monthPlaceHolder
          }
          search
          selection
          options={monthOptions}
          onChange={(event, val) => handleMonthChange(event, val)}
          value={month}
          open={isOpen}
          onClick={handleOpen}
          onBlur={handleClose}
          disabled={props.isDisabled}
        />
      </div>
    );
  }

  if (props.isMonthPicker) {
    return (
      <MonthInput
        className={classNames(props.className, styles.small)}
        {...{
          ...props,
          onChange: handleChange,
          name: 'month',
          placeholder: 'Month',
          iconPosition: 'right',
          animation: 'glow',
          clearable: true,
          autoComplete: 'off',
          closable: true,
        }}
        placeholder={
          props.monthPlaceHolder === undefined
            ? 'Month'
            : props.monthPlaceHolder
        }
        value={data}
        disabled={props.isDisabled}
      />
    );
  }

  if (props.isYearPicker) {
    return (
      <YearInput
        className={classNames(props.className, styles.small)}
        {...{
          ...props,
          onChange: handleChange,
          name: 'year',
          iconPosition: 'right',
          animation: 'glow',
          clearable: true,
          autoComplete: 'off',
          closable: true,
        }}
        placeholder={
          props.yearPlaceHolder === undefined ? 'Year' : props.yearPlaceHolder
        }
        value={data}
        disabled={props.isDisabled}
      />
    );
  }

  return (
    <div className={errorMessage === undefined ? ' ' : styles.errorField}>
      <MonthRangeInput
        className={classNames(props.className, styles.small)}
        {...{
          ...props,
          onChange: handleChange,
          name: 'month',
          iconPosition: 'right',
          dateFormat: 'MMM YYYY',
          animation: 'glow',
          clearable: true,
          autoComplete: 'off',
          closable: true,
        }}
        placeholder={
          props.monthYearPlaceHolder === undefined
            ? 'Month Year Range'
            : props.monthYearPlaceHolder
        }
        value={data}
        disabled={props.isDisabled}
        minDate={data?.split('-')[0].trim()}
      />
      {errorMessage && (
        <div>
          <ATMLabel basic className={styles.errorLabel} pointing="above">
            {errorMessage}
          </ATMLabel>
        </div>
      )}
    </div>
  );
};
