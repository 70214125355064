import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { Dimmer } from 'semantic-ui-react';
import ClassNames from 'classnames';
import get from 'lodash.get';
import orderBy from 'lodash.orderby';
import { Location } from 'history';
import QueryString from 'qs';
import { useLocation, useHistory } from 'react-router-dom';
import pick from 'lodash.pick';
import { secondLargestValue } from '../../../utils/common';
import styles from './ORGDataTable.module.scss';
import {
  MOLTableToolBar,
  IMOLTableToolbar,
  IMOLTableToolbarFilteredBy,
} from './MOLTableToolBar/MOLTableToolBar.component';
import {
  ITableFilter,
  IMOLTableFilter,
} from './MOLTable-filter/MOLTable-filter.component';
import { ATMLoader } from '../../atoms/ATMLoader/ATMLoader.component';
import { ATMPagination } from '../../atoms/ATMPagination/ATMPagination.component';
import {
  ATMTable,
  IATMTableCellProps,
  IATMTableHeaderCellProps,
  IATMTableProps,
} from '../../atoms/ATMTable/ATMTable.component';
import { ATMIcon } from '../../atoms/ATMIcon/ATMIcon.component';
import { ATMDropdown } from '../../atoms/ATMDropdown/ATMDropdown.component';
import { ATMGrid } from '../../atoms/ATMGrid/ATMGrid.component';
import { ATMResponsive } from '../../atoms/ATMResponsive/ATMResponsive.component';

type IORGDataTable = Record<string, any>;

type IORGDataTableRowProps = {
  expandDisabled: boolean;
  isExpand: boolean;
  setIsExpand: React.Dispatch<React.SetStateAction<boolean>>;
};

export type IORGDataTableColumn<T extends IORGDataTable> = {
  index: string;
  title: React.ReactNode;
  width?: string;
  sortable?: boolean;
  render?: (
    value: any,
    record: T,
    index: number,
    props: IORGDataTableRowProps
  ) => React.ReactNode;
  cellProps?: IATMTableCellProps;
  headerProps?: IATMTableCellProps;
  childColumns?: Omit<IORGDataTableColumn<T>, 'childColumns'>[];
  isVisible?: boolean;
  visibilityToggle?: boolean;
  sortingOrder?: number;
  cellClass?: any;
  rowSpan?: any;
};

export type IORGDataTableQueryState<
  T extends string = any,
  U = { [K in T]: any }
> = {
  page: number;
  limit: number;
  sort?: string;
  order?: IATMTableHeaderCellProps['sorted'];
  filters?: ITableFilter[];
} & Partial<U>;

export type IORGDataTableOnUpdate = (
  data: Partial<IORGDataTableQueryState>
) => void;

// Allowed state to be used
const tableState: Array<keyof IORGDataTableQueryState> = [
  'page',
  'limit',
  'order',
  'sort',
  'filters',
];

const createURLString = (
  location: Location,
  data: Partial<IORGDataTableQueryState>,
  addParams?: string[]
) => {
  const query = QueryString.parse(location.search, { ignoreQueryPrefix: true });
  let path = location.pathname;

  const newQuery: Record<string, any> = {
    ...data,
  };

  const state = tableState.concat(addParams || []);

  // We will include the other url parameters when generating new url
  Object.keys(query).forEach((key) => {
    if (state.indexOf(key as keyof IORGDataTableQueryState) < 0) {
      newQuery[String(key)] = query[String(key)];
    }
  });

  if (newQuery && Object.keys(newQuery).length >= 1) {
    const urlReadyQuery = QueryString.stringify(newQuery);
    path += `?${urlReadyQuery}`;
  }

  return path;
};

type IRowProps<T extends IORGDataTable> = {
  rowKey: number;
  data: T;
  columnsData: IORGDataTableColumn<T>[];
  placeholder?: string | number;
  queryState: IORGDataTableQueryState;
  expandableRowDisabled?: (data: T, index: number) => boolean;
  expandableRowsComponent?: (
    data: T,
    props: IORGDataTableRowProps
  ) => React.ReactNode;
};

const Row = <T extends IORGDataTable>({
  data,
  rowKey,
  queryState,
  columnsData,
  placeholder,
  expandableRowDisabled,
  expandableRowsComponent,
}: React.PropsWithChildren<IRowProps<T>>) => {
  const [isExpand, setIsExpand] = useState(false);
  const props = {
    expandDisabled: expandableRowDisabled
      ? expandableRowDisabled(data, rowKey)
      : false,
    isExpand,
    setIsExpand,
  };

  const displayValue = (
    value,
    index,
    key,
    render: IORGDataTableColumn<T>['render']
  ) => {
    const display = render
      ? render(get(value, index), value, key, props)
      : get(value, index);

    if (value.emptyRow === 'emptyRow') {
      return '';
    }

    if (
      placeholder &&
      ((typeof display === 'string' && display.trim() === '') ||
        display === null ||
        display === undefined)
    ) {
      return placeholder;
    }

    return display;
  };

  return (
    <>
      <ATMTable.Row
        className={ClassNames(data.state, data.rowColor, data.emptyRow)}
      >
        {columnsData.map(
          (
            {
              index,
              render,
              cellProps,
              childColumns,
              isVisible,
              cellClass,
              rowSpan,
            },
            key
          ) => {
            if (isVisible === undefined ? true : isVisible) {
              if (childColumns && childColumns.length) {
                return childColumns.map(({ ...child }, childKey) => (
                  <ATMTable.Cell
                    {...child.cellProps}
                    key={`cell_${queryState.page}_${rowKey}_${key}_${childKey}`}
                  >
                    {displayValue(data, child.index, rowKey, child.render)}
                  </ATMTable.Cell>
                ));
              }
              return rowSpan ? (
                Object.keys(data).includes(index) && (
                  <ATMTable.Cell
                    {...cellProps}
                    key={`cell_${queryState.page}_${rowKey}_${key}`}
                    className={
                      cellClass &&
                      Object.keys(cellClass).includes(
                        typeof data[String(index)] === 'string'
                          ? data[String(index)].toLowerCase()
                          : data[String(index)]
                      )
                        ? cellClass[
                            typeof data[String(index)] === 'string'
                              ? data[String(index)].toLowerCase()
                              : data[String(index)]
                          ]
                        : ''
                    }
                    rowspan={
                      rowSpan &&
                      Object.keys(rowSpan).includes(
                        typeof data[String(index)] === 'string'
                          ? data[String(index)].toLowerCase()
                          : data[String(index)]
                      )
                        ? rowSpan[
                            typeof data[String(index)] === 'string'
                              ? data[String(index)].toLowerCase()
                              : data[String(index)]
                          ]
                        : ''
                    }
                  >
                    {displayValue(data, index, rowKey, render)}
                  </ATMTable.Cell>
                )
              ) : (
                <ATMTable.Cell
                  {...cellProps}
                  key={`cell_${queryState.page}_${rowKey}_${key}`}
                  className={
                    cellClass &&
                    Object.keys(cellClass).includes(
                      typeof data[String(index)] === 'string'
                        ? data[String(index)].toLowerCase()
                        : data[String(index)]
                    )
                      ? cellClass[
                          typeof data[String(index)] === 'string'
                            ? data[String(index)].toLowerCase()
                            : data[String(index)]
                        ]
                      : ''
                  }
                  rowspan={
                    rowSpan &&
                    Object.keys(rowSpan).includes(
                      typeof data[String(index)] === 'string'
                        ? data[String(index)].toLowerCase()
                        : data[String(index)]
                    )
                      ? rowSpan[
                          typeof data[String(index)] === 'string'
                            ? data[String(index)].toLowerCase()
                            : data[String(index)]
                        ]
                      : ''
                  }
                >
                  {displayValue(data, index, rowKey, render)}
                </ATMTable.Cell>
              );
            }
            return null;
          }
        )}
      </ATMTable.Row>
      {isExpand && expandableRowsComponent && (
        <ATMTable.Row>
          <ATMTable.Cell
            colSpan={columnsData.length}
            className={styles.noPadding}
          >
            {expandableRowsComponent(data, props)}
          </ATMTable.Cell>
        </ATMTable.Row>
      )}
    </>
  );
};

export type IORGDataTableProps<T extends IORGDataTable> = Omit<
  IATMTableProps,
  'columns'
> & {
  history?: boolean; // This will attach table's state in the URL
  columns: IORGDataTableColumn<T>[];
  data: T[];
  total?: number; // Only add total if pagination is managed in the server
  rowsPerPageOptions?: number[]; // List of options for rows per page
  loading?: boolean;
  sortable?: boolean;
  showPagination?: boolean;
  addParams?: string[]; // Ability to add additional state in the table
  counter?: boolean; // This will show or hide `Showing 1 of 10` info text
  filteredBy?: IMOLTableToolbarFilteredBy; // This will allow update of text in filter. If false, it will removed the Filtered By info text
  onChange?: (state: IORGDataTableQueryState) => void;
  children?: (data: {
    state: IORGDataTableQueryState;
    setState: IORGDataTableOnUpdate;
  }) => {
    toolbars?: IMOLTableToolbar[] | IMOLTableFilter;
    filters?: IMOLTableFilter;
  };
  noDataText?: string | React.ReactNode;
  tableLabel?: any;
  noMarginTop?: boolean;
  customFilter?: boolean;
  customFilterContent?: React.ReactNode;
  customFilterBtn?: React.ReactNode;
  compact?: boolean;
  columnFilter?: boolean;
  handleUpdatedColumnData?: any;
  dragDropIcon?: React.ReactNode;
  tableHeight?: string;
  filterCollapsed?: boolean;
  placeholder?: string | number;
  defaultRowsPerPage?: number;
  expandableRowDisabled?: (data: T, index: number) => boolean;
  expandableRowsComponent?: (
    data: T,
    props: IORGDataTableRowProps
  ) => React.ReactNode;
  columnSettingsHeader?: string;
};

const totalRowsOptions = [10];

const defaultState: IORGDataTableQueryState = {
  page: 1,
  limit: 10,
};

const ORGDataTable = <T extends IORGDataTable>({
  data,
  columns,
  loading = false,
  history = false,
  rowsPerPageOptions = totalRowsOptions,
  addParams,
  counter = false,
  celled = true,
  children,
  onChange,
  filteredBy,
  showPagination = true,
  noDataText,
  tableLabel,
  total: totalProp,
  noMarginTop = false,
  customFilter = false,
  customFilterContent,
  customFilterBtn,
  compact,
  columnFilter = false,
  dragDropIcon,
  handleUpdatedColumnData,
  tableHeight = '',
  filterCollapsed = false,
  placeholder = String.fromCharCode(8211),
  defaultRowsPerPage,
  expandableRowDisabled,
  expandableRowsComponent,
  columnSettingsHeader,
  ...props
}: React.PropsWithChildren<IORGDataTableProps<T>>) => {
  const locationData = useLocation();
  const historyData = useHistory();
  const total = totalProp || data.length;
  const [columnsData, setColumnsData] = useState(columns);

  useEffect(() => {
    setColumnsData((values) => {
      return values.map((column) => {
        const result = columns.find((value) => value.index === column.index);

        return {
          ...(result || column),
          isVisible: column.isVisible,
          visibilityToggle: column.visibilityToggle,
        };
      });
    });
  }, [columns, setColumnsData]);

  let count = data.length;
  const arr = [...rowsPerPageOptions];
  const secondHighest = secondLargestValue(arr);
  const defaultLimit = useMemo(() => {
    if (showPagination && defaultRowsPerPage) {
      if (!rowsPerPageOptions.includes(defaultRowsPerPage)) {
        rowsPerPageOptions.push(defaultRowsPerPage);
      }

      return defaultRowsPerPage;
    }

    if (showPagination && rowsPerPageOptions.length > 2) {
      return secondHighest;
    }

    if (showPagination) {
      return rowsPerPageOptions[0];
    }
    return data.length || 99999;
  }, [showPagination, defaultRowsPerPage, rowsPerPageOptions, data]);

  // const defaultLimit = useMemo(
  //   () => (showPagination ? rowsPerPageOptions[0] : data.length || 99999),
  //   [showPagination, rowsPerPageOptions, data]
  // );

  const getParams = () => {
    const params: Record<string, any> = QueryString.parse(locationData.search, {
      ignoreQueryPrefix: true,
    });

    params.page = Number(params.page) || defaultState.page;
    params.limit = Number(params.limit) || defaultLimit;
    // Only get the parameters needed by table state
    return pick(
      params,
      tableState.concat(addParams || [])
    ) as IORGDataTableQueryState;
  };

  // If history is true, get the url parameters and set as state on initialized
  const [queryState, setQueryState] = useState<IORGDataTableQueryState>(
    history
      ? getParams()
      : {
          ...defaultState,
          limit: defaultLimit,
        }
  );

  // This will update the state limit if the defaultLimit changes
  useEffect(() => {
    if (!showPagination) {
      setQueryState((state) => ({
        ...state,
        limit: defaultLimit,
      }));
    }
  }, [showPagination, defaultLimit, setQueryState]);

  // This will handle all the state changes for this component
  const handleUpdate: IORGDataTableOnUpdate = useCallback(
    (items) => {
      const state = {
        ...items,
      };

      // If empty state, we must always populate these params
      state.page = Number(state.page) || defaultState.page;
      state.limit = Number(state.limit) || defaultLimit;
      if (history) {
        historyData.push(
          createURLString(
            locationData,
            {
              ...state,
            },
            addParams
          )
        );
      } else {
        let params = {
          ...state,
        };

        // Remove filters if empty
        if (params.filters && params.filters.length === 0) {
          const { filters, ...newParams } = params;

          params = newParams;
        }

        setQueryState(
          pick(
            params,
            tableState.concat(addParams || [])
          ) as IORGDataTableQueryState
        );
      }
    },
    [setQueryState, defaultLimit, historyData, locationData, history, addParams]
  );

  const handleSort = useCallback(
    (sortParam, orderParam) => {
      const order: IORGDataTableQueryState['order'] =
        orderParam === 'descending' ? 'ascending' : 'descending';
      const params = {
        sort: sortParam,
        order,
      };

      handleUpdate({
        ...queryState,
        ...params,
      });
    },
    [handleUpdate, queryState]
  );

  // If history is true, the table will react on the uri changes
  // then call the onChange for the parent container
  useEffect(() => {
    if (locationData && history) {
      const params: IORGDataTableQueryState = getParams();
      setQueryState(params);

      if (onChange) {
        onChange(params);
      }
    }
  }, [locationData, history, onChange]); // eslint-disable-line

  // If history is false, this will react to changes on state
  // and send it back to the parent container via onChange props
  useEffect(() => {
    if (onChange && !history) {
      onChange(queryState);
    }
  }, [queryState, onChange, history]);

  const colGroup: React.ReactNode[] = [];

  const hasRowSpan = useMemo(
    () =>
      !columnsData.every(
        (column) => column.childColumns && column.childColumns.length
      ),
    [columnsData]
  );

  const getHeader = (
    {
      title,
      index,
      headerProps,
      sortable = true,
      childColumns = [],
    }: IORGDataTableColumn<T>,
    key: string,
    isChild = false
  ) => {
    const hProps: IATMTableHeaderCellProps = {
      ...headerProps,
      key: `header_${key}`,
    };

    if (props.sortable && sortable && !childColumns.length) {
      hProps.sorted = queryState.sort === index ? queryState.order : undefined;
      hProps.onClick = () => handleSort(index, queryState.order);
    }

    if (childColumns.length) {
      hProps.colSpan = childColumns.length;
    }

    if (!isChild && !childColumns.length && hasRowSpan) {
      hProps.rowSpan = 2;
    }

    return <ATMTable.HeaderCell {...hProps}>{title}</ATMTable.HeaderCell>;
  };

  const headerList: React.ReactNode[][] = [];

  columnsData.forEach(({ ...value }, key) => {
    if (value.isVisible === undefined ? true : value.isVisible) {
      headerList[0] = [...(headerList[0] ?? []), getHeader(value, `${key}`)];

      if (value.childColumns && value.childColumns.length) {
        headerList[1] = [
          ...(headerList[1] ?? []),
          ...value.childColumns.map((v, k) => {
            colGroup.push(<col key={`col_${key}_${k}`} width={value.width} />);

            return getHeader(v, `${key}_${k}`, true);
          }),
        ];
      } else {
        colGroup.push(<col key={`col_${key}`} width={value.width} />);
      }
    }
  });

  let content = [
    <ATMTable.Row key="no-data" className={styles.noDataRow}>
      <ATMTable.Cell colSpan={colGroup.length} textAlign="center">
        <div className={styles.noDataText}>
          <span>
            {noDataText !== undefined ? (
              noDataText
            ) : (
              <>
                <ATMIcon name="info circle" />
                No records/data found for the selected criteria
              </>
            )}
          </span>
        </div>
      </ATMTable.Cell>
    </ATMTable.Row>,
  ];

  if (data.length) {
    let offset = 0;
    let list = [...data];

    // This will trigger the data table's built-in pagination and sorting.
    if (!totalProp) {
      offset = (queryState.page - 1) * queryState.limit;

      if (props.sortable && queryState.sort && queryState.order) {
        list = orderBy(
          list,
          [queryState.sort],
          [queryState.order === 'ascending' ? 'asc' : 'desc']
        );
      }
    }

    // Slice the data based on the limit set in the table
    list = list.slice(offset, offset + queryState.limit);

    count = list.length;

    content = list.map(({ ...value }, rowKey) => (
      <Row
        key={`row_${total}_${queryState.page}_${rowKey}`}
        data={value}
        rowKey={rowKey}
        queryState={queryState}
        columnsData={columnsData}
        placeholder={placeholder}
        expandableRowDisabled={expandableRowDisabled}
        expandableRowsComponent={expandableRowsComponent}
      />
    ));
  }

  let optionLength = Math.ceil(Number(total) / Number(queryState.limit));

  // If the computed option length will equal to 0, we will set it to 1
  // so it will create at least 1 page
  if (optionLength === 0) {
    optionLength = 1;
  }

  const childrenProps = children
    ? children({
        state: queryState,
        setState: handleUpdate,
      })
    : {};

  const handleColumnFilterApply = useCallback(
    (filteredArray: React.SetStateAction<IORGDataTableColumn<T>[]>) => {
      setColumnsData(filteredArray);
      if (handleUpdatedColumnData) {
        handleUpdatedColumnData(filteredArray);
      }
    },
    [handleUpdatedColumnData, setColumnsData]
  );

  // const toolbars = useMemo(
  //   () => childrenProps.toolbars,
  //   [childrenProps.toolbars]
  // );
  // const filters = useMemo(() => childrenProps.filters, [childrenProps.filters]);

  return (
    <div
      className={ClassNames(styles.wrapper, {
        [styles.noMarginTop]: noMarginTop,
      })}
    >
      <Dimmer active={loading} inverted>
        <ATMLoader size="large">Loading</ATMLoader>
      </Dimmer>

      <MOLTableToolBar
        counter={counter}
        state={queryState}
        total={total}
        tableLabel={tableLabel}
        count={count}
        toolbars={childrenProps.toolbars ?? []}
        filters={childrenProps.filters}
        customFilter={customFilter}
        customFilterBtn={customFilterBtn}
        customFilterContent={customFilterContent}
        handleChange={handleUpdate}
        filteredBy={filteredBy}
        columnFilter={columnFilter}
        columns={columnsData}
        handleColumnFilterApply={handleColumnFilterApply}
        dragDropIcon={dragDropIcon}
        filterCollapsed={filterCollapsed}
        columnSettingsHeader={columnSettingsHeader}
      />
      <div
        style={tableHeight ? { height: tableHeight, overflowY: 'auto' } : {}}
      >
        <ATMTable compress={compact} celled={celled} {...props}>
          <ATMResponsive greaterThan="mobile">
            <colgroup>{colGroup}</colgroup>
          </ATMResponsive>
          <ATMTable.Header>
            {headerList.map((headers, key) => (
              <ATMTable.Row key={key}>{headers}</ATMTable.Row>
            ))}
          </ATMTable.Header>
          <ATMTable.Body>{content}</ATMTable.Body>
        </ATMTable>
      </div>
      {showPagination && (
        <div className={styles.footer}>
          <ATMGrid columns={3} padded="vertically">
            <ATMGrid.Row>
              <ATMGrid.Column>
                {rowsPerPageOptions.length > 1 &&
                  total > rowsPerPageOptions[0] && (
                    <div className={styles.rowPerPage}>
                      <label>Rows per page:</label>
                      <ATMDropdown
                        size="mini"
                        value={queryState.limit}
                        onChange={(_, item) => {
                          handleUpdate({
                            ...queryState,
                            limit: Number(item.value),
                            page: 1,
                          });
                        }}
                        options={rowsPerPageOptions.map((num) => ({
                          key: num,
                          value: num,
                          text: num,
                        }))}
                      />
                    </div>
                  )}
              </ATMGrid.Column>
              <ATMGrid.Column textAlign="center">
                {total > rowsPerPageOptions[0] && (
                  <ATMPagination
                    size="mini"
                    activePage={queryState.page}
                    totalPages={optionLength}
                    onPageChange={(_, item) =>
                      handleUpdate({
                        ...queryState,
                        page: Number(item.activePage),
                      })
                    }
                  />
                )}
              </ATMGrid.Column>
            </ATMGrid.Row>
          </ATMGrid>
        </div>
      )}
    </div>
  );
};

export { ORGDataTable };
