import React from 'react';
import {
  SearchProps,
  Search,
  SearchResultProps,
  SearchResultsProps,
} from 'semantic-ui-react';

export interface IATMSearch extends React.FC<SearchProps> {
  Category: typeof Search.Category;
  Result: typeof Search.Result;
  Results: typeof Search.Results;
}

const ATMSearch: IATMSearch = (props) => {
  return <Search size="small" placeholder="Search" {...props} />;
};

ATMSearch.Category = Search.Category;
ATMSearch.Result = Search.Result;
ATMSearch.Results = Search.Results;

export type IATMSearchProps = SearchProps;
export type IATMSearchResultProps = SearchResultProps;
export type IATMSearchResultsProps = SearchResultsProps;

export { ATMSearch };
